import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SendSmsModal = ({
  show,
  toggle,
  handleRecreateMandate,
  isResuming,
}) => {
  //   const [resumption, setIsResumption] = useState();
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={toggle} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Send SMS</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to send sms to this customer?</p>
        {/* <Form>
          <Form.Label>Resumption Date</Form.Label>
          <Form.Control
            type="date"
            value={resumption}
            onChange={(e) => setIsResumption(e.target.value)}
          />
        </Form> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            // backgroundColor: "red",
            fontSize: "0.8rem",
            fontWeight: 500,
            border: 0,
          }}
          onClick={() => {
            handleRecreateMandate();
          }}
          disabled={isResuming}
        >
          {isResuming ? "Sending, please wait.." : "Send Sms"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
