import React, { useContext, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import { generateMandateReport } from "../services";
import { saveAs } from "file-saver";
import { AuthContext } from "../helpers/AuthContext";
import { notify } from "../components/actions/CommonActions";

export const Reports = () => {
  const [mandateReport, setMandateReport] = useState({
    frequency: "",
    bank: "",
    status: "",
    startDate: "",
    mandateCode: "",
    amount: "",
  });

  const [transactionReport, setTransactionReport] = useState({
    bank: "",
    status: "",
    startDate: "",
    endDate: "",
    mandateCode: "",
    amount: "",
    subscriberCode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { frequencies, statuses, user, banks } = useContext(AuthContext);

  const handleDownloadMandateReport = async () => {
    const token = sessionStorage.getItem("token") || null;
    // let parsedData = JSON.parse(datas);
    // const token = parsedData.token;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/mandate/mandate-report?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(mandateReport),
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");

      // Create a blob URL for the data
      const blobUrl = window.URL.createObjectURL(blob);

      // Set the link's href to the blob URL
      link.href = blobUrl;

      // Set the download attribute with the desired filename
      link.setAttribute("download", "Mandate__Report.xlsx");

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);
      setIsLoading(false);
      notify("success", "success", "File downloaded successfully");
    } catch (error) {
      notify("danger", "OOPS", "Error downloading file");
      console.error("Error downloading file:", error);
      setIsLoading(false);
    }
  };

  const handleDownloadTransactionReport = async () => {
    const datas = sessionStorage.getItem("userdata") || null;
    let parsedData = JSON.parse(datas);
    const token = parsedData.token;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/transactions/transaction-report?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(transactionReport),
        }
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");

      // Create a blob URL for the data
      const blobUrl = window.URL.createObjectURL(blob);

      // Set the link's href to the blob URL
      link.href = blobUrl;

      // Set the download attribute with the desired filename
      link.setAttribute("download", "Transactions__Report.xlsx");

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Revoke the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);
      notify("success", "success", "File downloaded successfully");
      setIsLoading(false);
    } catch (error) {
      notify("danger", "OOPS", "Error downloading file");
      setIsLoading(false);
      console.error("Error downloading file:", error);
    }
  };

  const handleMandateChange = (e) => {
    const name = e.target.name;
    let value;

    switch (name) {
      case "bank":
        value = parseInt(e.target.value);
        break;

      case "frequency":
        value = parseInt(e.target.value);
        break;

      case "status":
        value = parseInt(e.target.value);
        break;

      default:
        value = e.target.value;
        break;
    }

    // Move this line inside the switch statement block
    setMandateReport({ ...mandateReport, [name]: value });
  };

  const handleTransactionChange = (e) => {
    const name = e.target.name;
    let value;

    switch (name) {
      case "bank":
        value = parseInt(e.target.value);
        break;

      // case "frequency":
      //   value = parseInt(e.target.value);
      //   break;

      // case "status":
      //   value = parseInt(e.target.value);
      //   break;

      default:
        value = e.target.value;
        break;
    }

    // Move this line inside the switch statement block
    setTransactionReport({ ...transactionReport, [name]: value });
  };

  // const statuses = ;
  // const statuses = JSON.parse(sessionStorage.getItem("statuses"));
  // console.log(statuses);
  return (
    <Tabs
      defaultActiveKey="profile"
      // id="uncontrolled-tab-example"
      className="mb-3 mt-3"
    >
      <Tab
        style={{ backgroundColor: "white" }}
        eventKey="profile"
        title="Mandate report"
      >
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Mandate Code</Form.Label>
              <Form.Control onChange={handleMandateChange} name="mandateCode" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                onChange={handleMandateChange}
                name="startDate"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Amount</Form.Label>
              <Form.Control onChange={handleMandateChange} name="amount" />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={handleMandateChange} name="status">
                <option>-Select-</option>
                {statuses?.data?.map((status) => {
                  return (
                    <option value={status?.id} key={status?.id}>
                      {status?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Frequency</Form.Label>
              <Form.Select onChange={handleMandateChange} name="frequency">
                <option>-Select-</option>
                {frequencies?.data?.map((frequency) => {
                  return (
                    <option value={frequency?.code} key={frequency?.id}>
                      {frequency?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Bank</Form.Label>
              <Form.Select onChange={handleMandateChange} name="bank">
                <option>-Select-</option>
                {/* <option value={998}>998</option> */}
                {banks?.data?.map((bank) => {
                  return (
                    <option value={bank.code} key={bank.code}>
                      {bank.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Date of initiation</Form.Label>
              <Form.Control
                type="date"
                onChange={handleMandateChange}
                name="createdAt"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Mandate Type</Form.Label>
              <Form.Select onChange={handleMandateChange} name="mandateType">
                <option>-Select-</option>
                <option value="Normal Mandate">Normal Mandate</option>
                <option value="E-Mandate">E-Mandate</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <div className="d-flex align-items-center justify-content-between">
            <p></p>
            <Button
              style={{
                backgroundColor: "#007dcc",
                fontSize: "0.8rem",
                fontWeight: 500,
              }}
              onClick={(e) => {
                e.preventDefault();
                handleDownloadMandateReport();
              }}
              disabled={isLoading}
            >
              {isLoading ? `Downloading, please wait...` : `Download`}
            </Button>
          </div>
        </Form>
      </Tab>
      <Tab
        style={{ backgroundColor: "white" }}
        eventKey="home"
        title="Transaction report"
      >
        <Form>
          {/* <Form.Group controlId="formGridCity">
            <Form.Label>Biller</Form.Label>
            <Form.Control />
          </Form.Group>

          <Form.Group controlId="formGridCity">
            <Form.Label>Product</Form.Label>
            <Form.Control />
          </Form.Group> */}

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Mandate Code</Form.Label>
              <Form.Control
                onChange={handleTransactionChange}
                name="mandateCode"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Subscriber Code</Form.Label>
              <Form.Control
                onChange={handleTransactionChange}
                name="subscriberCode"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Amount</Form.Label>
              <Form.Control onChange={handleTransactionChange} name="amount" />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Customer Bank</Form.Label>
              <Form.Select onChange={handleTransactionChange} name="bank">
                <option>-Select-</option>
                <option value="success">998</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Debit Status</Form.Label>
              <Form.Select onChange={handleTransactionChange} name="status">
                <option>-Select-</option>
                <option value="success">Success</option>
                <option value="failed">Failed</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Start Date</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  {/* CiCalendarDate component */}
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  name="startDate"
                  onChange={handleTransactionChange}
                  aria-label="Start Date"
                />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>End Date</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  {/* CiCalendarDate component */}
                </InputGroup.Text>
                <Form.Control
                  type="date"
                  name="endDate"
                  onChange={handleTransactionChange}
                  aria-label="End Date"
                />
              </InputGroup>
            </Form.Group>
          </Row>

          <div className="d-flex align-items-center justify-content-between">
            <p></p>
            <Button
              style={{
                backgroundColor: "#007dcc",
                fontSize: "0.8rem",
                fontWeight: 500,
              }}
              // type="submit"
              disabled={isLoading}
              onClick={handleDownloadTransactionReport}
            >
              {isLoading ? `Downloading, please wait...` : `Download`}
            </Button>
          </div>
        </Form>
      </Tab>
    </Tabs>
  );
};
