import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col, InputGroup, Table } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import {
  batchUploadFile,
  createMandate,
  createNewMandate,
  nameEnquiry,
  updateMandateFile,
  uploadBulkMandateDocument,
} from "../services";
import { formatDate, notify } from "../components/actions/CommonActions";
import { AuthContext } from "../helpers/AuthContext";
import { FiRefreshCcw } from "react-icons/fi";
// import jwt from "jsonwebtoken";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import * as XLSX from "xlsx";

export const CreateMandate = () => {
  const [mandate, setMandate] = useState({
    startDate: "",
    endDate: "",
    accountNumber: "",
    payerAddress: "",
    frequency: null,
    email: "",
    narration: "",
    bank: "",
    mandateBankCode: "",
    payer: "",
    accountName: "",
    amount: "",
    phoneNumber: "",
    subscriberCode: "",
    productId: 1355,
    mandateType: "",
    // mandateFile: "",
    // {
    //   // "fileExtension":"png",
    //   // "fileBase64EncodedString":"iVBORw0KGgoAAAANSUhEUgAAAHIAAAASCAYAAACHKYonAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAA/HSURBVGhDxVkHdFVVuv7OufcmN4UUIkEgRghESJBgaEIojggCT1DmsbCM88QyIsqs0QEpFvA9cZQXxGlSw6KDrAEiHRELQhAQEupESAiEHpJQ0m6/57zv3zcnlOfzsd48hj/rv/ucXf6z99//He3q1avmli1bEBYWhjsN4eHhsNls9W/XQCOaocfbBoFAAD6fTz0PHToU+/fvx4kTJ35yPzeAxt3pOhu2gtypaXC3hsHHW9m1rNNDqIBrZJ3J9f/LqU3OczqdGDRoELRly5aZycnJSEpKUgOC/0wQBsg34+LisCMvD2fPnEW4PRzBYBC6ZsJl8yNgc0D322DTgxC2hgVsMOCBYdP5zmfhxT+wbRFiWloaMjMzsXjxYjz55JOYNm0aJk6cCJfLVT9LgB9RQrNBj3BCj4wkv4Pwl1+Er6oGAY8PdocDdp7F0TQedrsTQbeL6AYPROEKDRE8T8ExzSbr3TDcl2H6amAE"
    // },
  });
  const [isCreatingMandate, setIsCreatingMandate] = useState(false);
  const [isFinding, setIsFinding] = useState(false);
  const [gettingAccountInfo, setGettingAccountInfo] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingDocFile, setIsUploadingDocFile] = useState(false);
  const [mandateFile, setMandateFile] = useState("");
  const [mandateDocumentFile, setMandateDocumentFile] = useState("");
  const { user, banks, institutions } = useContext(AuthContext);
  const [showFeedback, setShowFeedback] = useState("");
  const [showNameError, setShowNameError] = useState("");
  const [uploadedImageName, setUploadedImageName] = useState(null);
  const [uploadedDocImageName, setUploadedDocImageName] = useState(null);
  const [accountNo, setAccountNo] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isUploadFile, setIsUploadFile] = useState(false);
  const [batchFile, setBatchFile] = useState([]);
  const [uploadMandateDocFile, setUploadMandateDocFile] = useState({
    subscriberCode: "",
    mandateFile: "",
  });
  const imageRef = useRef(null);

  const imageUploadFileRef = useRef(null);

  const clearFields = () => {
    setMandate({
      username: "",
      password: "",
      startDate: "",
      endDate: "",
      accountNumber: "",
      payerAddress: "",
      frequency: 0,
      email: "",
      narration: "",
      bank: "",
      payer: "",
      accountName: "",
      amount: "",

      phoneNumber: "",
      subscriberCode: "",
      productId: 1355,
      mandateType: "",
      mandateFile: {
        // "fileExtension":"png",
        // "fileBase64EncodedString":"iVBORw0KGgoAAAANSUhEUgAAAHIAAAASCAYAAACHKYonAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsIAAA7CARUoSoAAAA/HSURBVGhDxVkHdFVVuv7OufcmN4UUIkEgRghESJBgaEIojggCT1DmsbCM88QyIsqs0QEpFvA9cZQXxGlSw6KDrAEiHRELQhAQEupESAiEHpJQ0m6/57zv3zcnlOfzsd48hj/rv/ucXf6z99//He3q1avmli1bEBYWhjsN4eHhsNls9W/XQCOaocfbBoFAAD6fTz0PHToU+/fvx4kTJ35yPzeAxt3pOhu2gtypaXC3hsHHW9m1rNNDqIBrZJ3J9f/LqU3OczqdGDRoELRly5aZycnJSEpKUgOC/0wQBsg34+LisCMvD2fPnEW4PRzBYBC6ZsJl8yNgc0D322DTgxC2hgVsMOCBYdP5zmfhxT+wbRFiWloaMjMzsXjxYjz55JOYNm0aJk6cCJfLVT9LgB9RQrNBj3BCj4wkv4Pwl1+Er6oGAY8PdocDdp7F0TQedrsTQbeL6AYPROEKDRE8T8ExzSbr3TDcl2H6amAE"
      },
    });
    setMandateFile("");
  };
  const token = sessionStorage.getItem("token");

  const handleClick = () => {
    // window.location.href = mandateFile;
    window.open(mandateFile, "_blank");
  };

  const handleUploadFileClick = () => {
    // window.location.href = mandateFile;
    window.open(mandateFile, "_blank");
  };

  const today = new Date();
  const todayDate = today.toISOString().substr(0, 10);

  const upload = (e) => {
    try {
      setIsUploading(true);
      // console.warn('image uploaded now ',e.target.files[0]);
      setUploadedImageName(e.target.files[0]?.name);
      const files = e.target.files;
      const formData = new FormData();
      formData.append("file", files[0]);
      fetch("https://staging-upgrade.fbninsurance.co/api/file-upload", {
        method: "POST",
        body: formData,
      }).then((resp) => {
        resp.json().then((result) => {
          setMandateFile(result?.data?.url);
          setIsUploading(false);

          setIsUploading(false);
          console.warn(result);
        });
      });
    } catch (error) {
      alert(error);
    }
  };

  const uploadDocument = (e) => {
    try {
      setIsUploading(true);
      // console.warn('image uploaded now ',e.target.files[0]);
      setUploadedDocImageName(e.target.files[0]?.name);
      const files = e.target.files;
      const formData = new FormData();
      formData.append("file", files[0]);
      fetch("https://staging-upgrade.fbninsurance.co/api/file-upload", {
        method: "POST",
        body: formData,
      }).then((resp) => {
        resp.json().then((result) => {
          setMandateDocumentFile(result?.data?.url);
          setUploadMandateDocFile({
            ...uploadMandateDocFile,
            mandateFile: result?.data?.url,
          });
          setIsUploading(false);

          setIsUploading(false);
        });
      });
    } catch (error) {
      alert(error);
    }
  };

  const getAccountInfo = async () => {
    setGettingAccountInfo(true);
    // const selectedBank = await banks.data.find(
    //   (bank) => bank.mandateCode === mandate.bank
    // );
    await nameEnquiry(mandate.accountNumber, mandate.bank)
      .then(async (res) => {
        // console.log("res from name Enquiry", res);
        setGettingAccountInfo(false);
        setShowFeedback("");

        // const amountWithoutSpaces = amount.replace(/\s/g, "");
        if (res.status === "success") {
          setMandate({
            ...mandate,
            accountName: res.response.accountName,
            // bank: selectedBank.code,
            // mandateBankCode: selectedBank.mandateCode,
          });
          setShowNameError("");
          setGettingAccountInfo(false);
          setAccountNo(false);
        }
        if (res?.status === "failed") {
          setShowNameError("name");
          setGettingAccountInfo(false);
          setMandate({
            ...mandate,
            accountName: "",
            // bank: selectedBank.code,
            // mandateBankCode: selectedBank.mandateCode,
          });
        }
      })
      .catch(async (error) => {
        console.log("errrrrrr", error);
        setGettingAccountInfo(false);
        if (error.response.data.required === "bank") {
          setShowFeedback("bank");
          return;
        }

        if (error.response.data.required === "account") {
          setShowFeedback("account");
          return;
        }
        setMandate({
          ...mandate,
          accountName: "",
          // bank: selectedBank.code,
          // mandateBankCode: selectedBank.mandateCode,
        });
        console.log("err from name enquiry", error);
      });
  };

  const handleSubmit = () => {
    newMandate();
  };

  const newMandate = async () => {
    try {
      setIsCreatingMandate(true);

      const selectedBank = await banks.data.find(
        (bank) => bank.mandateCode === mandate.bank
      );

      const constructedMandate = {
        mandateRequests: [
          {
            startDate: mandate.startDate,
            endDate: mandate.endDate,
            accountNumber: mandate.accountNumber,
            payerAddress: mandate.payerAddress,
            frequency: mandate.frequency,
            email: mandate.email,
            narration: mandate.narration,
            bank: selectedBank.code,
            mandateBankCode: selectedBank.mandateCode,
            payer: mandate.payer,
            accountName: mandate.accountName,
            amount: mandate.amount,
            phoneNumber: mandate.phoneNumber,
            subscriberCode: mandate.subscriberCode,
            productId: mandate.productId,
            mandateFile: mandate.mandateFile,
            mandateType: mandate.mandateType,
          },
        ],
      };

      const secretKey = process.env.REACT_APP_SECRET;

      function encryptData(data, secretKey) {
        const stringData = JSON.stringify(data);
        const encrypted = AES.encrypt(stringData, secretKey).toString();
        return encrypted;
      }

      const encryptedPayload = encryptData(
        {
          ...constructedMandate?.mandateRequests[0],
          userId: user?.userData?.id,
          mandateFile: mandateFile,
        },
        secretKey
      );

      console.log("encrypted payload: ", encryptedPayload);

      await createNewMandate(encryptedPayload, user)
        .then((res) => {
          notify("success", "Mandate Created", "Mandate created successfully");
          clearFields();
          imageRef.current.value = "";
          setMandateFile("");
          setUploadedImageName(null);
          setIsCreatingMandate(false);
        })
        .catch((error) => {
          setIsCreatingMandate(false);
          console.log(error);
          notify("danger", "OOPS", error.response.data.response);
        });
    } catch (error) {
      setIsCreatingMandate(false);
      console.log(error);
      notify("danger", "OOPS", "something went wrong");
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    console.log(name);
    let value;
    switch (name) {
      // case "accountNumber":
      //   value = e.target.value;
      //   if (value.length === 10) {
      //     setAccountNo(true)
      // getAccountInfo();
      //   }

      //   break;

      case "frequency":
        value = parseInt(e.target.value);

        break;

      default:
        value = e.target.value;
        break;
    }
    // if(mandate?.accountNumber.length === 10 ){
    //   setAccountNo(true)
    //   getAccountInfo();
    //   }

    // const value = e.target.value;
    // console.log(name, value);
    setMandate({ ...mandate, [name]: value });
  };

  const handleMandateDoc = (e) => {
    setUploadMandateDocFile({
      ...uploadMandateDocFile,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomerInfo = async (policy) => {
    try {
      imageRef.current.value = "";
      setMandateFile("");
      setUploadedImageName(null);
      setIsFinding(true);
      const info = await fetch(
        `https://api.crm360.sanlam.com.ng/api/v1/policies/policy/${policy}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI5LCJpYXQiOjE3MDI5NzI0MDR9.f_XDF_s3q-oEwHz1YtwsykirmqNSkUx2X8N7XntoxBw`,
            Accept: "application/json",
          },
        }
      );

      const response = await info.json(); // Await the result of info.json()
      clearFields();

      if (response?.message == "Policy not found") {
        notify("danger", "OOPS", response?.message);
      }

      setMandate({
        ...mandate,
        payer: response?.data?.details?.lifeAssured,
        email: response?.data?.details?.prpEmail,
        phoneNumber: response?.data?.details?.prpTel,
        payerAddress: response?.data?.details?.prpPhysicalAddr,
        subscriberCode: response?.data?.details?.polPolicyNo,
        amount: response?.data?.details?.grossPremium,
        narration: `SMMS/${response?.data?.details?.polPolicyNo}/${response?.data?.details?.prpTel}/${response?.data?.details?.lifeAssured}`,
        // startDate: formatDate(response?.data?.details?.polEffectiveDate),
        endDate: formatDate(response?.data?.details?.polMaturityDate),
        // accountNumber: response?.data?.details?.endrBankAccNo,
        // accountName: response?.data?.details?.endrBankAccName,
      });

      setIsFinding(false);
    } catch (error) {
      setIsFinding(false);
      // notify("danger", "OOPs", error.response.data.response.message);
      console.log("Error fetching customer info:", error);
    }
  };

  const handleRadioChange = (type) => {
    setMandate({ ...mandate, mandateType: type });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Define the expected headers
        const expectedHeaders = [
          "startDate",
          "endDate",
          "accountNumber",
          "payerAddress",
          "frequency",
          "email",
          "narration",
          "bank",
          "mandateBankCode",
          "payer",
          "accountName",
          "amount",
          "phoneNumber",
          "subscriberCode",
          "productId",
          "mandateType",
          "dryRun"
        ]; // Replace with your expected headers

        // Get the headers from the first row of the Excel file
        const headersInFile = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        // Check if the headers in the file match the expected headers
        const isValidFile = expectedHeaders.every((header) =>
          headersInFile.includes(header)
        );

        if (!isValidFile) {
          notify(
            "danger",
            "Invalid file",
            "Invalid file structure. Please upload a file with the correct headers."
          );
          return;
        }

        // const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Assuming the first row contains headers/names for the properties
        const headers = headersInFile;

        // Create an array of objects using headers as property names
        // const dataArray = excelData.slice(1).map((row) => {
        //   const obj = {};
        //   headers.forEach((header, index) => {
        //     obj[header] = row[index];
        //   });
        //   return obj;
        // });

        const dataArray = XLSX.utils
          .sheet_to_json(worksheet, { header: 1 })
          .slice(1)
          .map((row) => {
            const obj = {};
            row.forEach((cell, index) => {
              // Check if the cell is a date and convert it to JS Date
              if (headers[index] && worksheet[`${headers[index]}_t`] === "n") {
                const dateCode = cell;
                const jsDate = XLSX.SSF.parse_date_code(dateCode);
                obj[headers[index]] = jsDate;
              } else {
                obj[headers[index]] = cell;
              }
            });
            return obj;
          });

        setBatchFile(dataArray);
        dataArray?.length > 0 ? setIsUploaded(true) : setIsUploaded(false);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleUploadFile = () => {
    setIsUploadFile(true);
    batchUploadFile(batchFile, user)
      .then((res) => {
        if (res.status === "success") {
          // const storedPage = sessionStorage.getItem("currentPage");
          // const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
          notify("success", "success", res?.message);
          setIsUploadFile(false);
          // toggle();
          // refetch(initialPage);
        } else {
          notify("danger", "OOPS", "something went wrong");
          setIsUploadFile(false);
        }
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsUploadFile(false);
      });
  };

  const handleUploadBulkImage = async () => {
    setIsUploadingDocFile(true);
    await uploadBulkMandateDocument(uploadMandateDocFile)
      // .then((res)=> res.json())
      .then((res) => {
        // console.log('res ', res);
        notify("success", "Mandate updated successfully.", `${res?.message}`);
        setUploadMandateDocFile({
          subscriberCode: "",
          mandateFile: "",
        });
        setIsUploadingDocFile(false);
        setMandateDocumentFile("");
        setUploadedDocImageName(null);
      })
      .catch((err) => {
        console.log(err);
        notify("danger", "OOPS", `${err?.response?.data?.message}`);
        setIsUploadingDocFile(false);
      });
  };

  const handleUploadMandateFile = async () => {
    setIsUploadingDocFile(true);
    await updateMandateFile(uploadMandateDocFile)
      // .then((res)=> res.json())
      .then((res) => {
        // console.log('res ', res);
        notify("success", "Mandate updated successfully.", `${res?.message}`);
        setUploadMandateDocFile({
          subscriberCode: "",
          mandateFile: "",
        });
        setIsUploadingDocFile(false);
        setMandateDocumentFile("");
        setUploadedDocImageName(null);
      })
      .catch((err) => {
        console.log(err);
        notify("danger", "OOPS", `${err?.response?.data?.message}`);
        setIsUploadingDocFile(false);
      });
  };

  const handleDocumentFileChange = () => {};

  return (
    <Form className="mt-4">
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Subcriber code</Form.Label>
          <Form.Control
            value={uploadMandateDocFile.subscriberCode}
            type="text"
            name="subscriberCode"
            onChange={handleMandateDoc}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Upload Mandate Document File</Form.Label>
          <p
            onClick={() => {
              mandateFile === ""
                ? imageUploadFileRef.current.click()
                : handleUploadFileClick();
            }}
            style={{ marginBottom: 0 }}
          >
            {mandateDocumentFile === ""
              ? "CLICK TO UPLOAD"
              : "Image uploaded, click to view"}
          </p>
          {uploadedDocImageName && (
            <p style={{ marginBottom: 0, fontSize: 15 }}>
              {uploadedDocImageName}
            </p>
          )}
          {mandateDocumentFile !== "" && (
            <button
              onClick={(e) => {
                e.preventDefault();
                imageUploadFileRef.current.value = "";
                setMandateDocumentFile("");
                setUploadedDocImageName(null);
              }}
              style={{
                color: "red",
                border: 0,
                backgroundColor: "transparent",
                fontSize: "0.8rem",
              }}
            >
              Delete image
            </button>
          )}
          <input
            // value={mandateFile}
            className="upload-image"
            type="file"
            onChange={(e) => {
              uploadDocument(e);
            }}
            name="mandateFile"
            style={{ opacity: 0, position: "absolute" }}
            ref={imageUploadFileRef}
          />
          <code>{isUploading ? "uploading, please wait..." : ""}</code>
        </Form.Group>

        <Form.Group className="mt-1">
          <Button
            variant="outline-secondary"
            onClick={(e) => {
              e.preventDefault();
              handleUploadMandateFile();
            }}
            disabled={isUploadFile}
          >
            {isUploadingDocFile ? "Submitting..." : "Submit"}
          </Button>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Label>Mandate Type</Form.Label>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Check
            type="radio"
            id={`normal-mandate`}
            label="Normal Mandate"
            checked={mandate.mandateType === "Normal Mandate"}
            onChange={() => handleRadioChange("Normal Mandate")}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Check
            type="radio"
            id={`e-mandate`}
            label="E-Mandate"
            checked={mandate.mandateType === "E-Mandate"}
            onChange={() => handleRadioChange("E-Mandate")}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Upload File</Form.Label>
          <InputGroup>
            <Form.Control
              type="file"
              placeholder="Upload excel file."
              name="excelFile"
              onChange={handleFileChange}
            />
            <Button
              variant="outline-secondary"
              onClick={(e) => {
                e.preventDefault();
                handleUploadFile();
              }}
              disabled={isUploadFile}
            >
              {isUploadFile ? <FiRefreshCcw className="spin" /> : "Upload"}
            </Button>
          </InputGroup>
        </Form.Group>
      </Row>

      {batchFile?.length > 0 && (
        <>
          <code className="mt-4">
            The following {batchFile?.length} mandates would be created
          </code>
          <Table>
            <thead>
              <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
                <th width="11%">start Date</th>
                <th width="11%">end Date</th>
                <th width="11%">account Number</th>
                <th width="11%">payer Address</th>
                <th width="11%">frequency</th>
                <th width="11%">email</th>
                <th width="11%">narration</th>
                <th width="11%">bank</th>
                <th width="11%">mandate Bank Code</th>
                <th width="11%">payer</th>
                <th width="11%">account Name</th>
                <th width="11%">amount</th>
                <th width="11%">phone Number</th>
                <th width="11%">subscriber Code</th>
                <th width="11%">product Id</th>
                <th width="11%">mandate Type</th>
                <th width="11%">dryRun</th>
              </tr>
            </thead>
            {batchFile?.length < 1 ? (
              <tr>
                <td colSpan={"5"}>No mandate found...</td>
              </tr>
            ) : (
              <tbody>
                {batchFile?.map((mandate) => {
                  return (
                    <tr className="tr" onClick={() => {}}>
                      <td className="gray-td">{mandate?.startDate}</td>
                      <td className="gray-td">{mandate?.endDate}</td>
                      <td className="gray-td">{mandate?.accountNumber}</td>
                      <td className="gray-td">{mandate?.payerAddress}</td>
                      <td className="gray-td">{mandate?.frequency}</td>
                      <td className="gray-td">{mandate?.email}</td>
                      <td className="gray-td">{mandate?.narration}</td>
                      <td className="gray-td">{mandate?.bank}</td>
                      <td className="gray-td">{mandate?.mandateBankCode}</td>
                      <td className="gray-td">{mandate?.payer}</td>
                      <td className="gray-td">{mandate?.accountName}</td>
                      <td className="gray-td">{mandate?.amount}</td>
                      <td className="gray-td">{mandate?.phoneNumber}</td>
                      <td className="gray-td">{mandate?.subscriberCode}</td>
                      <td className="gray-td">{mandate?.productId}</td>
                      <td className="gray-td">{mandate?.mandateType}</td>
                      <td className="gray-td">{mandate?.dryRun}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        </>
      )}

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Subscriber Code</Form.Label>
          <InputGroup>
            <Form.Control
              value={mandate.subscriberCode}
              type="text"
              name="subscriberCode"
              onChange={handleChange}
            />
            <Button
              variant="outline-secondary"
              onClick={(e) => {
                e.preventDefault();
                getCustomerInfo(mandate?.subscriberCode);
              }}
              disabled={isFinding}
            >
              {isFinding ? <FiRefreshCcw className="spin" /> : "Find"}
              {/* {isFinding && <FiRefreshCcw />} */}
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            value={mandate.payer}
            type="text"
            name="payer"
            onChange={handleChange}
          />
        </Form.Group>

        {/* <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Biller</Form.Label>
          <Form.Control />
        </Form.Group> */}
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Product/Service</Form.Label>
          <Form.Select
            disabled
            value={mandate.productId}
            defaultValue="Select..."
            type="text"
            name="productId"
            onChange={handleChange}
          >
            <option>Select...</option>
            <option value={1355}>Sanlam Nigeria Direct Debit</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Amount (Naira)</Form.Label>
          <Form.Control
            value={mandate.amount}
            type="number"
            name="amount"
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Start Date</Form.Label>
          <InputGroup controlId="formGridCity">
            <InputGroup.Text>{<CiCalendarDate />}</InputGroup.Text>
            <Form.Control
              value={mandate.startDate}
              type="date"
              name="startDate"
              onChange={handleChange}
              aria-label="Amount (to the nearest dollar)"
              min={todayDate}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>End Date</Form.Label>
          <InputGroup controlId="formGridCity">
            <InputGroup.Text>{<CiCalendarDate />}</InputGroup.Text>
            <Form.Control
              value={mandate.endDate}
              type="date"
              name="endDate"
              onChange={handleChange}
              aria-label="Amount (to the nearest dollar)"
            />
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            value={mandate.phoneNumber}
            type="text"
            name="phoneNumber"
            onChange={handleChange}
          />
        </Form.Group>

        {/* <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Fixed/Variable amount</Form.Label>
          <Form.Select defaultValue="Select...">
            <option>Select...</option>
            <option>...</option>
          </Form.Select>
        </Form.Group> */}

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Email</Form.Label>
          <Form.Control
            value={mandate.email}
            type="text"
            name="email"
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Payer Address</Form.Label>
          <Form.Control
            value={mandate.payerAddress}
            type="text"
            name="payerAddress"
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Frequency</Form.Label>
          <Form.Select
            value={mandate.frequency}
            type="text"
            name="frequency"
            onChange={handleChange}
          >
            <option>-Select-</option>
            <option value={99}>Daily</option>
            <option value={1}>Monthly</option>
            <option value={3}>Quarterly</option>
            <option value={6}>Half-Yearly</option>
            <option value={12}>Yearly</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Customer Bank</Form.Label>
          <Form.Select
            value={mandate.bank}
            defaultValue="Select..."
            type="text"
            name="bank"
            onChange={handleChange}
            onBlur={(e) => {
              e.preventDefault();
              if (accountNo) {
                getAccountInfo();
              }
            }}
          >
            <option>Select...</option>
            {banks?.data?.map((bank) => {
              return (
                <option value={bank.mandateCode} key={bank.mandateCode}>
                  {bank.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback
            type="invalid"
            style={{ display: showFeedback === "bank" ? "block" : "none" }}
          >
            Please select a bank.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Mandate Image</Form.Label>
          <p
            onClick={() => {
              mandateFile === "" ? imageRef.current.click() : handleClick();
            }}
            style={{ marginBottom: 0 }}
          >
            {mandateFile === ""
              ? "CLICK TO UPLOAD"
              : "Image uploaded, click to view"}
          </p>
          {uploadedImageName && (
            <p style={{ marginBottom: 0, fontSize: 15 }}>{uploadedImageName}</p>
          )}
          {mandateFile !== "" && (
            <button
              onClick={(e) => {
                e.preventDefault();
                imageRef.current.value = "";
                setMandateFile("");
                setUploadedImageName(null);
              }}
              style={{
                color: "red",
                border: 0,
                backgroundColor: "transparent",
                fontSize: "0.8rem",
              }}
            >
              Delete image
            </button>
          )}
          <input
            // value={mandateFile}
            className="upload-image"
            type="file"
            onChange={(e) => {
              upload(e);
            }}
            name="mandateFile"
            style={{ opacity: 0, position: "absolute" }}
            ref={imageRef}
          />
          <code>{isUploading ? "uploading, please wait..." : ""}</code>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>Account Number</Form.Label>
          <Form.Control
            value={mandate.accountNumber}
            type="text"
            // maxLength={10}
            name="accountNumber"
            onChange={handleChange}
            onBlur={(e) => {
              e.preventDefault();
              // if (mandate.accountNumber?.length > 9) {
              // setAccountNo(true);
              getAccountInfo();
              // if(e.target.value.length == 10 ){
              // setAccountNo(true)
              // getAccountInfo();
              // }
            }}
          />
          <Form.Control.Feedback
            type="invalid"
            style={{ display: showFeedback === "account" ? "block" : "none" }}
          >
            Please provide an account number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>
            Account Name
            <FiRefreshCcw
              className={gettingAccountInfo ? "spin" : ""}
              style={{
                cursor: "pointer",
                fontSize: "0.7rem",
                marginLeft: "5px",
              }}
            />
          </Form.Label>
          <Form.Control
            disabled
            value={mandate.accountName}
            type="text"
            name="accountName"
            onChange={handleChange}
          />

          <Form.Control.Feedback
            type="invalid"
            style={{ display: showNameError === "name" ? "block" : "none" }}
          >
            Invalid account provided...
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Narration</Form.Label>
        <Form.Control
          value={mandate.narration}
          as="textarea"
          rows={1}
          type="text"
          name="narration"
          onChange={handleChange}
        />
      </Form.Group>
      <div className="d-flex align-items-center justify-content-between">
        <p></p>

        {/* <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={() => {
            clearFields();
          }}
          // disabled={isCreatingMandate}
        >
          clear
        </Button> */}

        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isCreatingMandate}
        >
          {isCreatingMandate ? "Creating mandate, please wait..." : "Submit"}
        </Button>
      </div>
    </Form>
  );
};
