import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../helpers/AuthContext";

export const FilterModal = ({
  toggleFilter,
  showFilter,
  handleChange,
  handleSearch,
  filterLoading,
  dataLoadingStatus,
  isFilterActive,
  setIsFilterActive,
}) => {
  const { statuses } = useContext(AuthContext);

  return (
    <Modal
      show={showFilter}
      onHide={toggleFilter}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Filter</Modal.Title>
          <span style={{ fontSize: "0.8rem", color: "#475467" }}>
            Edit and filter to reduce the number of entries on the mandate page
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Mandate Code</Form.Label>
              <Form.Control onChange={handleChange} name="mandateCode" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Subscriber Code</Form.Label>
              <Form.Control onChange={handleChange} name="subscriberCode" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={handleChange} name="approverStatus">
                <option>-Select-</option>
                {statuses?.data?.map((status) => {
                  return (
                    <option value={status?.id} key={status?.id}>
                      {status?.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Date Initiated</Form.Label>
              <Form.Control
                type="date"
                onChange={handleChange}
                name="startDate"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Data Loading</Form.Label>
              <Form.Select onChange={handleChange} name="dataLoading">
                <option>-Select-</option>
                {dataLoadingStatus?.map((status) => {
                  return (
                    <option value={status?.id} key={status?.id}>
                      {JSON.stringify(status?.status)}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            {/* <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Biller</Form.Label>
              <Form.Control onChange={handleChange} name="" />
            </Form.Group> */}

            {/* <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Product</Form.Label>
              <Form.Control onChange={handleChange} name="" />
            </Form.Group> */}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Bank</Form.Label>
              <Form.Control onChange={handleChange} name="bank" />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Date Approved</Form.Label>
              <Form.Control onChange={handleChange} name="dateApproved" />
            </Form.Group> */}

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Fixed/Variable Amount</Form.Label>
              <Form.Control onChange={handleChange} name="amount" />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Next Debit Date</Form.Label>
              <Form.Control
                type="date"
                onChange={handleChange}
                name="nextDebitDate"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Payer Name</Form.Label>
              <Form.Control onChange={handleChange} name="payer" />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={handleSearch}
          disabled={filterLoading}
        >
          {filterLoading ? "Searching" : "Search"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
