import React, { useContext, useEffect, useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { FiDownload, FiFilter, FiRefreshCcw } from "react-icons/fi";
import { GoDot, GoDotFill, GoFilter } from "react-icons/go";
import { FilterModal } from "../components/modals/FilterModal";
import { useNavigate } from "react-router";
import {
  getAllMandates,
  getE_MandatesStatus,
  getFilterAllMandates,
  getMandatesStatus,
  getMyMandates,
  getUsers,
} from "../services";
import { AuthContext } from "../helpers/AuthContext";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { notify } from "../components/actions/CommonActions";
import { IoCloseCircle, IoDocumentTextOutline } from "react-icons/io5";
import { BatchSuspendModal } from "../components/modals/BatchSuspendModal";
import { Dropdown } from "react-bootstrap";

export const AllMandates = () => {
  const [filter, setFilter] = useState({
    mandateCode: "",
    subscriberCode: "",
    approverStatus: "",
    startDate: "",
    bank: "",
    dateApproved: "",
    amount: "",
    nextDebitDate: "",
    payer: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [mandates, setMandates] = useState();
  const [isFetchingMandates, setIsFetchingMandates] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [numOfRecords, setNumOfRecords] = useState(8);
  const [numInput, setNumInput] = useState(8);
  const [showBatchSuspendModal, setShowBatchSuspendModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [users, setUsers] = useState();
  const { user, banks } = useContext(AuthContext);
  const [filterValues, setFilterValues] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const pageSize = numInput;

  //New pagination logic test //

  const fetchMandates = async (page) => {
    setIsLoading(true);
    try {
      const response = await getAllMandates(page, numInput);
      // setMandates(response.data);
      setTotalPages(response.totalPages);
      setCurrentPage(page);
      sessionStorage.setItem("currentPage", page);
      const mandatesWithUserNames = await Promise.all(
        response.data.map(async (mandate) => ({
          ...mandate,
          initiatorName: getInitiator(mandate.userId),
          approverName: getApprover(mandate.approverId),
        }))
      );
      setMandates(mandatesWithUserNames);
      // console.log(mandates);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getFilteredResults = async (page) => {
    setIsLoading(true);
    try {
      const filteredValues = JSON.parse(
        sessionStorage.getItem("filteredValues")
      );
      const response = await getFilterAllMandates(
        page,
        numInput,
        filteredValues
      );
      // setMandates(response.data);
      setTotalPages(response.totalPages);
      setCurrentPage(page);
      sessionStorage.setItem("currentPage", page);
      const mandatesWithUserNames = await Promise.all(
        response.data.map(async (mandate) => ({
          ...mandate,
          initiatorName: getInitiator(mandate.userId),
          approverName: getApprover(mandate.approverId),
        }))
      );
      setMandates(mandatesWithUserNames);
      // console.log(mandates);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedPage = sessionStorage.getItem("currentPage");
    const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
    const storedNum = sessionStorage.getItem("pageNum");
    const currentNum = storedNum ? storedNum : numInput;
    const filteredValues = JSON.parse(sessionStorage.getItem("filteredValues"));
    let isFilterActiveButton = sessionStorage.getItem('isFilterActiveButton')
    filteredValues ? setIsFilterActive(true) : setIsFilterActive(false);
    setNumInput(currentNum);
    isFilterActiveButton
      ? getFilteredResults(initialPage)
      : fetchMandates(initialPage);
  }, [currentPage]);

  const handlePageClick = (data) => {
    // const filteredValues = JSON.parse(sessionStorage.getItem("filteredValues"));
    const selectedPage = data.selected + 1;
    isFilterActive
      ? getFilteredResults(selectedPage)
      : fetchMandates(selectedPage);
  };

  //New pagination logic test //

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  // console.log(banks);
  const toggleBatchSuspendModal = () => {
    setShowBatchSuspendModal(!showBatchSuspendModal);
  };

  // const filterMandateStatus = () => {
  //   // console.log(mandates);
  //   let arr = mandates.filter((item)=> item?.approverStatus == '1' && item?.workFlowStatusDescription)
  //   console.log(arr);
  // };

  const handleGetMandatesStatus = async () => {
    setIsUpdating(true);
    const token = sessionStorage.getItem("token");
    const storedPage = sessionStorage.getItem("currentPage");
    const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
    await getMandatesStatus(token)
      .then((res) => {
        notify("success", "success", res.message);
      })
      .catch((error) => {
        console.log(error);
        notify("danger", "OOPS", error.response.data.response.message);
      });
    // refetch();
    fetchMandates(initialPage);
    setIsUpdating(false);
  };

  const handleGetE_MandatesStatus = async () => {
    setIsUpdating(true);
    const token = sessionStorage.getItem("token");
    const storedPage = sessionStorage.getItem("currentPage");
    const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
    await getE_MandatesStatus(token)
      .then((res) => {
        notify("success", "success", res.message);
      })
      .catch((error) => {
        console.log(error);
        notify("danger", "OOPS", error.response.data.response.message);
      });
    // refetch();
    fetchMandates(initialPage);
    setIsUpdating(false);
  };

  const {} = useQuery("all-users", getUsers, {
    onSuccess: (res) => {
      setUsers(res?.response);
    },
    onError: (error) => {
      notify("danger", "Error", error.response.data.message);
      console.log(error);
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    let value;
    switch (name) {
      case "frequency":
        value = parseInt(e.target.value);

        break;

      case "approverStatus":
        value = parseInt(e.target.value);

        break;

      default:
        value = e.target.value;
        break;
    }
    // const value = e.target.value;
    setFilter({ ...filter, [name]: value });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <p style={{ fontSize: "0.7rem", marginBottom: 0 }}>
        Refresh status
      </p>
    </Tooltip>
  );

  const frequencies = [
    { code: 1, name: "Monthly" },
    { code: 3, name: "Quarterly" },
    { code: 6, name: "Half-yearly" },
    { code: 12, name: "Yearly" },
    { code: 99, name: "Daily" },
  ];

  const findFrequency = (id) => {
    const frequency = frequencies?.find((freq) => freq.code === id);

    if (frequency) {
      return frequency?.name;
    } else {
      return "Invalid frequency";
    }
  };

  const statuses = sessionStorage.getItem("statuses");
  const parsedStatuses = JSON.parse(statuses);

  const findStatus = (id) => {
    const status = parsedStatuses?.data?.find((stat) => stat.id == id);
    return status?.name;
  };

  const handleRowRightClick = (mandate) => {
    const url = `/mandate-details/${mandate?.subscriberCode}`;
    window.open(url, "_blank");
  };

  function filterData(array, filters) {
    return array.filter((item) => {
      for (const key in filters) {
        if (item.hasOwnProperty(key) && item[key] !== filters[key]) {
          return false;
        }
      }
      return true;
    });
  }

  const getInitiator = (id) => {
    const user = users?.find((user) => user.id === parseInt(id));
    return user ? `${user.firstName} ${user.lastName}` : "loading...";
  };

  const getApprover = (id) => {
    const user = users?.find((user) => user.id === parseInt(id));
    return user ? `${user.firstName} ${user.lastName}` : "No approver";
  };

  const dataLoadingStatus = [
    {
      id: 1,
      status: true,
    },
    {
      id: 0,
      status: false,
    },
  ];

  const handleSearch = async () => {
    setFilterLoading(true);
    const filteredValues = Object.fromEntries(
      Object.entries(filter)
        .map(([name, value]) => {
          let processedValue;

          switch (name) {
            case "approverStatus":
            case "frequency":
              // Convert to integer if a valid integer, otherwise, set to undefined
              processedValue = /^\d+$/.test(value)
                ? parseInt(value)
                : undefined;
              break;
            default:
              processedValue = value;
              break;
          }
          return [name, processedValue];
        })
        .filter(([_, value]) => value !== undefined && value !== "")
    );
    if (Object.keys(filteredValues).length === 0) {
      notify("danger", "OOPs", "please enter filter options");
      return;
    }
    console.log(filteredValues);
    // const response = await getAllMandates(page, pageSize, {
    //   ...filteredValues,
    // });
    // fetchMandates();

    const storedPage = sessionStorage.getItem("currentPage");
    const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
    setIsFilterActive(true);
    sessionStorage.setItem("filteredValues", JSON.stringify(filteredValues));
    const response = await getFilterAllMandates(1, numInput, filteredValues);
    console.log("this is initial page: ", initialPage);
    console.log("this is the num: ", initialPage - (initialPage - 1));
    setTotalPages(response?.totalPages);
    sessionStorage.setItem("currentPage", initialPage);
    setMandates(response?.data);
    sessionStorage.setItem('isFilterActiveButton', "true")

    // console.log("this is the filter response:, ", response);

    setFilterValues(filteredValues);
    setFilteredData(filterData(mandates, filteredValues));
    setCurrentPage(initialPage);
    toggleFilter();
    setFilterLoading(false);
  };

  const dataToRender = filteredData.length > 0 ? filteredData : mandates;

  const navigate = useNavigate();
  return (
    <>
      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <button className="light-button" onClick={toggleFilter}>
            {" "}
            <GoFilter /> Filter
          </button>
          {isFilterActive && (
            <button
              className="light-button"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setIsFilterActive(false);
                sessionStorage.removeItem("filteredValues");
                sessionStorage.removeItem("isFilterActiveButton")
                fetchMandates(1);
                window.location.reload()
              }}
            >
              {" "}
              <IoCloseCircle /> Clear filters
            </button>
          )}
        </div>

        <div className="d-flex flex-row align-items-center gap-3">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 150, hide: 200 }}
            overlay={renderTooltip}
          >
            <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: "0.5px solid #585858",
                padding: "5px",
                fontSize: "0.7rem",
                borderRadius: "3px",
                fontWeight: "500",
                color: "#344054",
              }}
              // className="light-button"
              id="dropdown-basic"
            >
              <FiRefreshCcw
                className={isUpdating ? "spin" : ""}
                // onClick={handleGetMandatesStatus}
                style={{ cursor: "pointer" }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
              <Dropdown.Item 
              onClick={handleGetMandatesStatus}
              >
                Refresh Manual mandate
              </Dropdown.Item>
              <Dropdown.Item 
              onClick={handleGetE_MandatesStatus}
              >
                Refresh E-mandate
              </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </OverlayTrigger>
          {/* <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: "0.5px solid #585858",
                padding: "5px",
                fontSize: "0.7rem",
                borderRadius: "3px",
                fontWeight: "500",
                color: "#344054",
              }}
              // className="light-button"
              id="dropdown-basic"
            >
              <IoDocumentTextOutline /> Mandate status
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
              <Dropdown.Item onClick={filterMandateStatus}>
                Biller Initiated
              </Dropdown.Item>
              <Dropdown.Item onClick={filterMandateStatus}>
                Biller Approved
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: "0.5px solid #585858",
                padding: "5px",
                fontSize: "0.7rem",
                borderRadius: "3px",
                fontWeight: "500",
                color: "#344054",
              }}
              // className="light-button"
              id="dropdown-basic"
            >
              <IoDocumentTextOutline /> Batch Actions
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
              <Dropdown.Item onClick={toggleBatchSuspendModal}>
                Suspend
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Table hover responsive className="mt-4">
        <thead>
          <tr style={{ borderBottom: "0.5px solid #f1f1f1" }}>
            {/* Table header cells */}
            {/* Modify according to your data structure */}
            <th width="11%">Mandate Code</th>
            <th width="11%">Subscriber Code</th>
            <th>Amount</th>
            <th>Frequency</th>
            <th width="11%">Start Date</th>
            <th width="11%">End Date</th>
            <th>Date Added</th>
            <th>Next Debit Date</th>
            <th>Payer Name</th>
            <th>Approver Status</th>
            <th>Work Flow</th>
            <th>Initiated by</th>
            <th>Approved by</th>
            <th>Data Loaded</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <>
              <td className="gray-td">Loading, please wait...</td>
            </>
          ) : error ? (
            <>
              <td className="gray-td">Error: {error}</td>
            </>
          ) : dataToRender?.length === 0 ? (
            <>
              <td className="gray-td">No mandates found.</td>
            </>
          ) : (
            dataToRender?.map((mandate) => (
              <tr
                className="tr"
                onClick={() => {
                  navigate(`/mandate-details/${mandate?.subscriberCode}`, {
                    state: { mandate: mandate },
                  });
                  sessionStorage.setItem(
                    "location",
                    JSON.stringify({ state: { mandate: mandate } })
                  );
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  sessionStorage.setItem(
                    "location",
                    JSON.stringify({ state: { mandate: mandate } })
                  );
                  handleRowRightClick(mandate);
                }}
                key={mandate.id}
              >
                <td className="gray-td">{mandate?.eMandateCode || mandate?.mandateCode}</td>
                <td className="gray-td">{mandate.subscriberCode}</td>
                <td className="gray-td">{mandate.amount}</td>
                <td className="gray-td">{findFrequency(mandate.frequency)}</td>
                <td className="gray-td">
                  {new Date(mandate.startDate).toLocaleDateString()}
                </td>
                <td className="gray-td">
                  {new Date(mandate.endDate).toLocaleDateString()}
                </td>
                <td className="gray-td">
                  {new Date(mandate.createdAt).toLocaleDateString()}
                </td>
                <td className="gray-td">
                  {mandate?.nextDebitDate != null ? new Date(mandate.nextDebitDate).toLocaleDateString() : new Date(mandate.startDate).toLocaleDateString()}
                </td>
                <td className="gray-td">{mandate.payer}</td>
                <td className="gray-td">
                  <span
                    style={{
                      color: `${
                        mandate?.approverStatus == 1
                          ? "green"
                          : mandate.approverStatus == 2
                          ? "orange"
                          : "red"
                      }`,
                      fontSize: "0.8rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <GoDotFill style={{ fontSize: "0.6rem" }} />{" "}
                    {findStatus(mandate?.approverStatus)}
                  </span>
                </td>
                <td className="gray-td">{mandate.workFlowStatusDescription}</td>
                <td className="gray-td">{mandate.initiatorName}</td>
                <td className="gray-td">{mandate.approverName}</td>
                <td
                  style={{
                    color: mandate.dataLoading == 1 ? "green" : "#007dcc",
                    fontSize: 14,
                  }}
                >
                  {mandate.dataLoading === 1 ? "true" : "false"}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <div className="pagination-container">
        {/* Pagination controls */}
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          forcePage={currentPage - 1}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName="active-page"
          pageClassName={"item pagination-page "}
          previousClassName={"item previous"}
          nextClassName={"item next "}
          previousLabel={"Previous"}
          nextLabel={"Next"}
        />
        <input
          type="number"
          value={numInput}
          onChange={(e) => {
            setNumInput(parseInt(e.target.value));
            sessionStorage.setItem("pageNum", e.target.value);
          }}
        />
        <button
          style={{
            backgroundColor: "transparent",
            border: "0.5px solid #000",
            color: "#fff",
            backgroundColor: "#007dcc",
            padding: "4px",
            borderRadius: "5px",
          }}
          onClick={() => {
            const storedPage = sessionStorage.getItem("currentPage");
            const initialPage = storedPage ? parseInt(storedPage, 10) : 1;
            setNumInput(sessionStorage.getItem("pageNum"));
            fetchMandates(initialPage);
          }}
        >
          set
        </button>
      </div>

      <FilterModal
        toggleFilter={toggleFilter}
        showFilter={showFilter}
        handleChange={handleChange}
        handleSearch={handleSearch}
        filterLoading={filterLoading}
        dataLoadingStatus={dataLoadingStatus}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
      />

      <BatchSuspendModal
        show={showBatchSuspendModal}
        toggle={toggleBatchSuspendModal}
        refetch={fetchMandates}
      />
    </>
  );
};
