import { Chip } from "@mui/material";
import React, { useContext, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FiBook } from "react-icons/fi";
import { IoDocument } from "react-icons/io5";
import { useQuery } from "react-query";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
} from "recharts";
import {
  CiBadgeDollar,
  CiCircleMinus,
  CiPercent,
  CiReceipt,
  CiSquareCheck,
} from "react-icons/ci";
import {
  PiCaretDownFill,
  PiCaretUpFill,
  PiCheckCircleFill,
  PiWarningFill,
} from "react-icons/pi";
import { getAllMandates, getMyMandates, getTransactions } from "../services";
import { FaLock, FaLockOpen, FaMoneyBill } from "react-icons/fa";
import { notify } from "../components/actions/CommonActions";
import { grey, red } from "@mui/material/colors";
import { AuthContext } from "../helpers/AuthContext";

export const Dashboard = () => {
  const [transactions, setTransactions] = useState();
  const [mandates, setMandates] = useState();
  const [statusCounts, setStatusCounts] = useState([]);
  const { user, statuses } = useContext(AuthContext);
  const [sortedMandatesByNextDebitDate, setSortedMandatesByNextDebitDate] =
    useState();

  const numberFormat = (value, toFixed = 2) => {
    return Number(value)
      .toFixed(toFixed)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const { data, status, refetch, isSuccess, isLoading, isFetching } = useQuery(
    "transactions",
    getTransactions,
    {
      onSuccess: (res) => {
        const sortedData = res?.response.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Get the top 3 most recent entries
        const top3RecentEntries = sortedData.slice(0, 3);
        // console.log("redss", res?.response);

        let totalSuccessful = 0;
        let totalFailed = 0;

        // Group transactions by date
        const transactionsByDate = res?.response?.reduce((acc, transaction) => {
          const date = new Date(transaction.createdAt).toLocaleDateString();
          acc[date] = acc[date] || {
            successful: 0,
            failed: 0,
            totalAmountSuccessful: 0,
            totalAmountFailed: 0,
          };

          if (transaction.status === "success") {
            acc[date].successful++;
            acc[date].totalAmountSuccessful += transaction.amount || 0;
            totalSuccessful++;
          } else if (transaction.status === "failed") {
            acc[date].failed++;
            acc[date].totalAmountFailed += transaction.amount || 0;
            totalFailed++;
          }

          return acc;
        }, {});

        // Convert the grouped transactions into an array
        const resultArray = Object.keys(transactionsByDate).map((date) => ({
          date,
          successful: transactionsByDate[date].successful,
          failed: transactionsByDate[date].failed,
          successful: transactionsByDate[date].totalAmountSuccessful,
          failed: transactionsByDate[date].totalAmountFailed,
        }));

        setTransactions(resultArray);
      },
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const {} = useQuery(
    "my-mandates",
    () => {
      if (user?.userData?.role === 999) {
        return getMyMandates(user?.userData?.id);
      } else {
        return getAllMandates();
      }
    },
    {
      onSuccess: (res) => {
        setMandates(res);
        // console.log("this is res", res);
        const approverStatuses = res?.data?.map(
          (entry) => entry.approverStatus
        );
        const statusCountsObj = approverStatuses.reduce((acc, status) => {
          const statusName =
            statuses?.data?.find((s) => s.id === status)?.name || "Unknown";
          acc[statusName] = (acc[statusName] || 0) + 1;
          return acc;
        }, {});

        const statusCountsArray = Object.keys(statusCountsObj).map(
          (statusName) => ({
            approverStatus: statusName,
            count: statusCountsObj[statusName],
          })
        );

        setStatusCounts(statusCountsArray);

        // =========================================================================================================

        const sortedData = [...res?.data].sort((a, b) => {
          const dateA = new Date(a.nextDebitDate);
          const dateB = new Date(b.nextDebitDate);

          return dateA - dateB;
        });

        const top5Data = sortedData.slice(0, 5);

        setSortedMandatesByNextDebitDate(top5Data);

        // console.log(res?.data, top5Data);

        // =========================================================================================================
      },
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const CustomLegend = ({ data }) => (
    <ul
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: 0,
        margin: 0,
      }}
    >
      {data.map((entry, index) => (
        <li
          key={`legend-${index}`}
          style={{ color: COLORS[index % COLORS.length] }}
        >
          {`${entry.approverStatus}: ${entry.count}`}
        </li>
      ))}
    </ul>
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p
            style={{ margin: 0, cursor: "pointer" }}
          >{`${data.approverStatus}: ${data.count}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between gap-2 mt-3"
        style={{ height: "98px" }}
      >
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted" style={{ fontSize: "0.9rem" }}>
              Transactions count
            </p>
            <h5 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h5>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <CiBadgeDollar
              style={{ fontSize: "1.5rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted" style={{ fontSize: "0.9rem" }}>
              Successful transactions
            </p>
            <h5 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h5>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <CiSquareCheck
              style={{ fontSize: "1.3rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted" style={{ fontSize: "0.9rem" }}>
              Failed transactions
            </p>
            <h5 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h5>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <CiCircleMinus
              style={{ fontSize: "1.3rem" }}
              className="text-primary"
            />
          </div>
        </div>
        <div
          className="w-25 h-100 rounded p-2 d-flex align-items-center justify-content-between flex-direction-row ml-3"
          style={{ border: "1px solid #d4d4d8" }}
        >
          <div>
            <p className="mb-3 text-muted" style={{ fontSize: "0.9rem" }}>
              Mandates count
            </p>
            <h5 className="mb-0" style={{ color: "#000" }}>
              {numberFormat(124567)}
            </h5>
          </div>

          <div
            className="rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: "35px",
              height: "35px",
              backgroundColor: "#e6f2fa",
            }}
          >
            <CiReceipt
              style={{ fontSize: "1.6rem" }}
              className="text-primary"
            />
          </div>
        </div>
      </div>
      <Row
        className="mt-4"
        style={{
          height: "calc(100vh - 100px)",
          gap: "15px",
          // backgroundColor: "red",
        }}
      >
        <Col
          xs={7}
          style={{
            height: "50%",
            border: `0.5px solid ${grey[400]}`,
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Chip
            style={{ color: "#007dcc", fontSize: "0.6rem", fontWeight: "600" }}
            label="Transactions"
            size="small"
          />

          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={1000}
              height={300}
              data={transactions}
              margin={{
                top: 20,
                right: 30,
                top: 20,
                bottom: 5,
              }}
            >
              <Tooltip contentStyle={{ fontSize: 12 }} />
              <XAxis dataKey="date" tick={{ fontSize: 12 }} />
              <YAxis tick={{ fontSize: 12 }} />
              <Line
                type="monotone"
                dataKey="successful"
                stroke="#007dcc"
                strokeWidth={0.5}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col
          xs={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            border: `0.5px solid ${grey[400]}`,
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Chip
            style={{
              color: "#007dcc",
              alignSelf: "start",
              fontSize: "0.6rem",
              fontWeight: "600",
            }}
            label="Mandates"
            size="small"
          />
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Tooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegend data={statusCounts} />} />
              <Pie
                data={statusCounts}
                cx="50%" // Center x-axis
                cy="50%" // Center y-axis
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="count"
              >
                {statusCounts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Col>

        <Col
          xs={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // height: "50%",
            border: `0.5px solid ${grey[400]}`,
            // padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Chip
            style={{
              color: "#007dcc",
              alignSelf: "start",
              marginBottom: "10px",
              fontSize: "0.6rem",
              fontWeight: "600",
            }}
            label="UPCOMING DEBITS"
            size="small"
          />
          <Table hover>
            <thead>
              <tr>
                <th>Subscriber Code</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {sortedMandatesByNextDebitDate?.map((mandate) => {
                const newDate = new Date(mandate?.nextDebitDate);
                return (
                  <tr style={{ fontSize: "0.9rem" }}>
                    <td>{mandate?.subscriberCode}</td>
                    <td>{newDate.toDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>

        <Col
          xs={7}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            border: `0.5px solid ${grey[400]}`,
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <BarChart
            width={500}
            height={300}
            data={transactions}
            margin={{
              top: 20,
              right: 30,
              top: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" orientation="left" stroke="#82ca9d" />
            <YAxis yAxisId="right" orientation="right" stroke="#de4400" />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="successful" fill="#82ca9d" />
            <Bar yAxisId="right" dataKey="failed" fill="#de4400" />
          </BarChart>
        </Col>
      </Row>
    </>

    //   <div id="detail"></div>
  );
};
