import { Box, Divider, Drawer } from "@mui/material";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { getMandateHistory, getUsers } from "../../services";
import { notify } from "../actions/CommonActions";
import { AuthContext } from "../../helpers/AuthContext";

export const MandateHistory = ({
  anchor,
  state,
  toggleDrawer,
  subscriberCode,
}) => {
  const [history, setHistory] = useState();
  const [users, setUsers] = useState();
  const { statuses } = useContext(AuthContext);

  const { data, status, isFetching, isLoading } = useQuery(
    "mandate-history",
    () =>
      getMandateHistory(subscriberCode)
        .then((res) => {
          if (res?.status === "failed") {
            notify("danger", "Error", res?.response);
            // setIsFetchingMandates(false);
            console.log("errrrrror", res);
            return;
          } else {
            setHistory(res?.response);
            // console.log("this is the response", res);
          }
        })
        .catch((error) => {
          console.log(error);
          notify("danger", "Error", "Something went wrong");
        }),
    {
      enabled: !!subscriberCode,
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const {} = useQuery("all-users", getUsers, {
    onSuccess: (res) => {
      setUsers(res?.response);
    },
    onError: (error) => {
      notify("danger", "Error", "Something went wrong");
      console.log(error);
    },
  });

  // console.log("uuuuuuseeeeerrrrss", users);

  return (
    <div>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
          }}
          role="presentation"
          className="p-4"
        >
          {/* <p>
            History for{" "}
            <code style={{ color: "#007dcc" }}>{subscriberCode}</code>
          </p> */}
          <p style={{ color: "#344054", fontSize: "20px", fontWeight:"600" }}>
            Mandate history
          </p>

          <div style={{backgroundColor: "#F9FAFB", padding:"20px"}}>
<p style={{ color: "#344054", fontSize: "20px", fontWeight:"600" }}>{subscriberCode}</p>
          {users &&
            history?.map((item) => {
              const approvalStatus = statuses.data.find(
                (stat) => stat.id === parseInt(item.statusId)
              );

              const user = users.find(
                (user) => user.id === parseInt(item.userId)
              );
              return (
                <div
                  key={item.id}
                  style={{ marginBottom: "10px",
                  borderBottom: "1px solid #0000001F",}}
                  // className="mt-3"
                >
                  <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Status:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px", color: `${
                        parseInt(item?.statusId) === 1
                          ? "green"
                          : parseInt(item?.statusId) === 2
                          ? "orange"
                          : "red"
                      }`,}}>{approvalStatus?.name}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Note:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{item?.note}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Date:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{new Date(item?.createdAt).toLocaleString()}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Action by:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{user?.firstName} {user?.lastName}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Action performed:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{item?.actionPerformed}</p>
              </div>
                  {/* <p
                    style={{
                      color: `${
                        parseInt(item?.statusId) === 1
                          ? "green"
                          : parseInt(item?.statusId) === 2
                          ? "orange"
                          : "red"
                      }`,
                    }}
                  >
                    Status: {approvalStatus?.name}
                  </p>
                  <p>Note: {item?.note}</p>
                  <p>Date: {new Date(item?.createdAt).toLocaleString()}</p>
                  <p>
                    Action by: {user?.firstName} {user?.lastName}
                  </p>
                  <p>Action performed: {item?.actionPerformed}</p> */}
                  {/* <p>Date: {new Date(item.updatedAt).toLocaleDateString()}</p> */}
                  {/* <Divider sx={{ bgcolor: "secondary.light" }} /> */}
                </div>
              );
            })}
            </div>
        </Box>
      </Drawer>
    </div>
  );
};
