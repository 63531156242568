import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { notify } from "../actions/CommonActions";
import { getMyMissedPayments } from "../../services";

export const ManualDebitModal = ({
  toggleManualDebit,
  showManualDebit,
  manualDebit,
  setManualDebit,
  amount,
  handleManualDebit,
  newDebitMandate,
  isManualDebit,
  mandateDetails,
  selectedMonth,
  setSelectedMonth,
  setDebitReason,
  //   handleUpdateMandateFormChange,
  //   mandateForm,
  //   updateMandate,
  //   setMandateForm,
  //   isUpdatingMandate,
}) => {
  const [numMonths, setNumMonths] = useState(null);
  const [missedPayments, setMissedPayments] = useState([]);
  const [missedId, setMissedId] = useState(null);

  const { data, status, refetch, isFetching, isSuccess, isLoading } = useQuery(
    "missed-payments",
    () =>
      getMyMissedPayments(mandateDetails?.subscriberCode)
        .then((res) => {
          if (res?.status === "Failed") {
            notify("danger", "Error", "Error getting missed payments");

            return;
          } else {
            setMissedPayments(res?.response);
          }
        })
        .catch((error) => {
          console.log(error);
          notify("danger", "Error", "Something went wrong");
        }),
    {
      enabled: !!mandateDetails, // Only enable the query when mandateDetails is available
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const handleRadioChange = (month, id) => {
    setSelectedMonth(month);
    setMissedId(id);
  };

  return (
    <Modal
      show={showManualDebit}
      onHide={toggleManualDebit}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Manual Debit</Modal.Title>
          <span style={{ fontSize: "0.8rem", color: "#475467" }}>
            Debit missed payments for this mandate manually here
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* {missedPayments.length < 1 ? (
          <p>There are currently no missed payments on this mandate</p>
        ) : (
          <>
            <Form>
              <Row className="mb-3">
                <Form.Group>
                  {missedPayments?.map((missed) => {
                    const month = new Date(missed.month);
                    return (
                      <Form.Check
                        key={missed.id}
                        type="radio"
                        id={`custom-switch-${month.toDateString()}`}
                        label={month.toDateString()}
                        checked={selectedMonth === missed.month}
                        onChange={() =>
                          handleRadioChange(missed.month, missed.id)
                        }
                      />
                    );
                  })}
                </Form.Group>
              </Row>
            </Form>
            </>
          )} */}
        <p>Premium amount: N{mandateDetails.amount}</p>
        <Form>
          <Form.Group>
            <Form.Label>Reason for debiting</Form.Label>
            <Form.Control
              // value={manualDebit.reason}
              onChange={(e) => setDebitReason(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* {missedPayments.length > 0 && ( */}
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
          onClick={() => {
            newDebitMandate(missedId, refetch);
          }}
          disabled={isManualDebit}
        >
          {isManualDebit ? "Please wait..." : "Proceed to debit"}
        </Button>
        {/* )} */}
      </Modal.Footer>
    </Modal>
  );
};
