import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { editMandate, nameEnquiry, registerNewUser } from "../../services";
import { formatDate, notify } from "../actions/CommonActions";
import { AuthContext } from "../../helpers/AuthContext";
import { CiCalendarDate } from "react-icons/ci";

export const EditMandateModal = ({
  show,
  toggle,
  mandateDetails,
  mandateCode,
  setMandateDetails,
  //   handleChange,
  //   mandate,
}) => {
  const [mandate, setMandate] = useState({
    startDate: mandateDetails?.startDate?.split(" ")[0],
    endDate: mandateDetails?.endDate?.split(" ")[0],
    accountNumber: mandateDetails?.accountNumber,
    payerAddress: mandateDetails?.payerAddress,
    frequency: mandateDetails?.frequency,
    email: mandateDetails?.email,
    narration: mandateDetails?.narration,
    bank: mandateDetails?.bank,
    payer: mandateDetails?.payer,
    accountName: mandateDetails?.accountName,
    amount: mandateDetails?.amount,
    phoneNumber: mandateDetails?.phoneNumber,
    subscriberCode: mandateDetails?.subscriberCode,
    productId: mandateDetails?.productId,
    mandateBankCode: mandateDetails?.mandateBankCode,
    approverStatus: mandateDetails?.approverStatus
  });
  // console.log('mandateDetails ',mandateDetails);
  const [mandateFile, setMandateFile] = useState(mandateDetails?.mandateFile);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { user, institutions, banks } = useContext(AuthContext);
  const [gettingAccountInfo, setGettingAccountInfo] = useState(false);
  const [showFeedback, setShowFeedback] = useState("");

  // console.log("this is banks", banks);

  const getAccountInfo = async () => {
    setGettingAccountInfo(true);
    nameEnquiry(mandate.accountNumber, mandate.mandateBankCode)
      .then(async (res) => {
        // console.log("res from name Enquiry", res);
        setGettingAccountInfo(false);
        setShowFeedback("");

        const selectedBank = await banks.data.find(
          (bank) => bank.mandateCode === mandate.mandateBankCode
        );

        // const amountWithoutSpaces = amount.replace(/\s/g, "");
        if (res.response.responseCode === "00") {
          setMandate({
            ...mandate,
            accountName: res.response.accountName,
            bank: selectedBank.code,
            mandateBankCode: selectedBank.mandateCode,
          });
        }

        // if (res.response.responseCode === "00") {
        //   setMandate({
        //     ...mandate,
        //     accountName: res.response.accountName,
        //   });
        // }
      })
      .catch((error) => {
        setGettingAccountInfo(false);
        if (error.response.data.required === "bank") {
          setShowFeedback("bank");
          return;
        }

        if (error.response.data.required === "account") {
          setShowFeedback("account");
          return;
        }
        console.log("err from name enquiry", error);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    let value;

    switch (name) {
      // case "bank":
      //   value = parseInt(e.target.value);
      //   break;

      case "frequency":
        value = parseInt(e.target.value);
        break;

      default:
        value = e.target.value;
        break;
    }

    // console.log(name, value);
    setMandate({ ...mandate, [name]: value });
  };

  const upload = (e) => {
    e.preventDefault();
    try {
      setIsUploading(true);
      console.warn(e.target.files);
      const files = e.target.files;
      const formData = new FormData();
      formData.append("file", files[0]);
      fetch("https://staging-upgrade.fbninsurance.co/api/file-upload", {
        method: "POST",
        body: formData,
      }).then((resp) => {
        resp.json().then((result) => {
          setMandateFile(result?.data?.url);
          setIsUploading(false);

          setIsUploading(false);
          console.warn(result);
        });
      });
    } catch (error) {
      alert(error);
    }
  };

  const handleEditMandate = () => {
    setIsEditing(true);
    editMandate(
      { ...mandate, mandateFile: mandateFile, mandateCode: mandateCode },
      mandateDetails?.id,
      user
    )
      .then((res) => {
        notify("success", "success", res?.message);
        setMandateDetails(res?.response);
        setIsEditing(false);
        toggle();
      })
      .catch((error) => {
        notify("danger", "OOPS", error?.response?.data?.message);
        setIsEditing(false);
        console.log(error);
      });
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <div>
          <Modal.Title>Edit Mandate</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-4">
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Subscriber Code</Form.Label>
              {/* <InputGroup> */}
              <Form.Control
                value={mandate?.subscriberCode}
                type="text"
                name="subscriberCode"
                onChange={handleChange}
                // defaultValue={mandateDetails.subscriberCode}
              />

              {/* </InputGroup> */}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Payer</Form.Label>
              <Form.Control
                value={mandate?.payer}
                type="text"
                name="payer"
                onChange={handleChange}
                defaultValue={mandate?.payer}
              />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Biller</Form.Label>
          <Form.Control />
        </Form.Group> */}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Product/Service</Form.Label>
              <Form.Select
                value={mandate?.productId}
                // defaultValue={mandateDetails.productId}
                type="text"
                name="productId"
                onChange={handleChange}
                disabled
              >
                <option>Select...</option>
                <option value={1355}>Sanlam Nigeria Direct Debit</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Amount (Naira)</Form.Label>
              <Form.Control
                value={mandate?.amount}
                type="text"
                name="amount"
                onChange={handleChange}
                // defaultValue={mandateDetails.amount}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Start Date</Form.Label>
              <InputGroup controlId="formGridCity">
                <InputGroup.Text>{<CiCalendarDate />}</InputGroup.Text>
                <Form.Control
                  value={mandate?.startDate}
                  type="datetime"
                  name="startDate"
                  onChange={handleChange}
                  aria-label="Amount (to the nearest dollar)"
                  //   defaultValue={mandateDetails.startDate.split(" ")[0]}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>End Date</Form.Label>
              <InputGroup controlId="formGridCity">
                <InputGroup.Text>{<CiCalendarDate />}</InputGroup.Text>
                <Form.Control
                  value={mandate?.endDate}
                  type="datetime"
                  name="endDate"
                  onChange={handleChange}
                  aria-label="Amount (to the nearest dollar)"
                  //   defaultValue={mandateDetails.endDate.split(" ")[0]}
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                value={mandate?.phoneNumber}
                type="text"
                name="phoneNumber"
                onChange={handleChange}
                // defaultValue={mandateDetails.phoneNumber}
              />
            </Form.Group>

            {/* <Form.Group as={Col} controlId="formGridState">
          <Form.Label>Fixed/Variable amount</Form.Label>
          <Form.Select defaultValue="Select...">
            <option>Select...</option>
            <option>...</option>
          </Form.Select>
        </Form.Group> */}

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={mandate?.email}
                type="text"
                name="email"
                onChange={handleChange}
                // defaultValue={mandateDetails.email}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Payer Address</Form.Label>
              <Form.Control
                value={mandate?.payerAddress}
                type="text"
                name="payerAddress"
                onChange={handleChange}
                // defaultValue={mandateDetails.payerAddress}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Frequency</Form.Label>
              <Form.Select
                value={mandate?.frequency}
                type="text"
                name="frequency"
                onChange={handleChange}
                // defaultValue={mandateDetails.frequency}
              >
                <option>-Select-</option>
                <option value={99}>Daily</option>
                <option value={1}>Monthly</option>
                <option value={3}>Quarterly</option>
                <option value={6}>Half-Yearly</option>
                <option value={12}>Yearly</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>Customer Bank</Form.Label>
              <Form.Select
                defaultValue={mandate?.mandateBankCode}
                // defaultValue="Select..."
                type="text"
                name="mandateBankCode"
                onBlur={() => {
                  getAccountInfo();
                }}
                onChange={handleChange}
                // defaultValue={mandateDetails.bank}
              >
                <option>Select...</option>
                {banks?.data.map((bank) => {
                  return (
                    <option value={bank.mandateCode} key={bank?.id}>
                      {bank?.name}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback
                type="invalid"
                style={{ display: showFeedback === "bank" ? "block" : "none" }}
              >
                Please select a bank.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Mandate Image</Form.Label>
              {/* <Form.Control /> */}
              <input
                className="upload-image"
                type="file"
                onChange={upload}
                name="mandateFile"
              />
              <a href={mandateFile} target="_blank" rel="noopener noreferrer">
                view mandate file
              </a>
              <code>{isUploading ? "uploading, please wait..." : ""}</code>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                value={mandate?.accountNumber}
                type="text"
                name="accountNumber"
                onChange={handleChange}
                onBlur={() => {
                  getAccountInfo();
                }}
                // defaultValue={mandateDetails.accountNumber}
              />
              <Form.Control.Feedback
                type="invalid"
                style={{
                  display: showFeedback === "account" ? "block" : "none",
                }}
              >
                Please provide an account number.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridZip">
              <Form.Label>Account Name</Form.Label>
              <Form.Control
                value={mandate?.accountName}
                type="text"
                disabled
                name="accountName"
                onChange={handleChange}
                // defaultValue={mandateDetails.accountName}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Narration</Form.Label>
            <Form.Control
              value={mandate?.narration}
              as="textarea"
              rows={3}
              type="text"
              name="narration"
              onChange={handleChange}
              //   defaultValue={mandateDetails.narration}
            />
          </Form.Group>

          <div className="d-flex align-items-center justify-content-between">
            <p></p>
            <Button
              style={{
                backgroundColor: "#007dcc",
                fontSize: "0.8rem",
                fontWeight: 500,
              }}
              onClick={() => {
                handleEditMandate();
              }}
              disabled={isEditing}
            >
              {isEditing ? "Saving, please wait..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          style={{
            backgroundColor: "#007dcc",
            fontSize: "0.8rem",
            fontWeight: 500,
          }}
        >
          Search
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
