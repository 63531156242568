import React, { useContext, useEffect, useState } from "react";
import { Col, Dropdown, DropdownDivider, Row, Table } from "react-bootstrap";
import mandate from "../assets/images/mandate.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notify } from "../components/actions/CommonActions";
import { UpdateMandateModal } from "../components/modals/UpdateMandateModal";
import { useMutation, useQuery } from "react-query";
import {
  debitMandate,
  deleteMandate,
  getFile,
  recreateMandate,
  resumeSuspendedMandate,
  retrieveMandate,
  sendSmsMandate,
  suspendMandate,
  updateMandateDetails,
  updateResumptionDate,
} from "../services";
import { ApproveMandateModal } from "../components/modals/ApproveMandateModal";
import { RejectMandateModal } from "../components/modals/RejectMandateModal";
import { AuthContext } from "../helpers/AuthContext";
import { ManualDebitModal } from "../components/modals/ManualDebitModal";
import { DeleteMandateModal } from "../components/modals/DeleteMandateModal";
import { FiCheck } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";
import { EditMandateModal } from "../components/modals/EditMandateModal";
import { SuspendMandateModal } from "../components/modals/SuspendMandateModal";
import { ResumeMandateModal } from "../components/modals/ResumeMandateModal";
import { CiEdit } from "react-icons/ci";
import { UpdateResumptionDateModal } from "../components/modals/UpdateResumptionDateModal";
import { Box, Chip, Divider, Drawer } from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { MandateHistory } from "../components/modals/MandateHistory";
import { RecreateMandateModal } from "../components/modals/RecreateMandateModal";
import { SendSmsModal } from "../components/modals/SendSmsModal";
import { TransactionHistory } from "../components/modals/TransactionHistory";

export const MandateDetails = () => {
  const [mandateDetails, setMandateDetails] = useState();
  const [isFetchingMandates, setIsFetchingMandates] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showSuspendMandate, setShowSuspendMandate] = useState(false);
  const [showResumeMandate, setShowResumeMandate] = useState(false);
  const [showRecreateMandate, setShowRecreateMandate] = useState(false);
  const [showEditMandate, setShowEditMandate] = useState(false);
  const [mandatePdf, setMandatePdf] = useState("");
  const [showManualDebit, setShowManualDebit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateResumptionDate, setShowUpdateResumptionDate] =
    useState(false);
  const [showSendSms, setShowSendSms] = useState(false)
  const [recreateEmandate, setRecreateEmandate] = useState(false)
  const [showReject, setShowReject] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isManualDebit, setIsManualDebit] = useState(false);
  const [isSuspending, setIsSuspending] = useState(false);
  const [isResuming, setIsResuming] = useState(false);
  const { user, statuses, banks } = useContext(AuthContext);
  const [isUpdatingMandate, setIsUpdatingMandate] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [debitReason, setDebitReason] = useState("");
  const [mandateForm, setMandateForm] = useState({
    mandateCode: "",
    phoneNumber: "",
    payerName: "",
    payerAddress: "",
    status: "",
    workFlowStatus: "",
    emailAddress: "",
  });

  // let { id } = useParams();
  // console.log("mandateDetails id ", mandateDetails);

  const [state, setState] = React.useState({
    right: false,
  });

  const [stateTransact, setStateTransact] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const toggleDrawerTransact = (anchor, open) => (event) => {
    console.log('transact ', anchor, open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateTransact({ ...stateTransact, [anchor]: open });
  };

  const anchor = "right";
  const anchorLeft = "right";

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [mandateFile, setMandateFile] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const toggleUpdate = () => {
    setShowUpdate(!showUpdate);
  };

  const toggleUpdateResumptionDate = () => {
    setShowUpdateResumptionDate(!showUpdateResumptionDate);
  };
  const toggleSuspendMandate = () => {
    setShowSuspendMandate(!showSuspendMandate);
  };
  const toggleResumeMandate = () => {
    setShowResumeMandate(!showResumeMandate);
  };
  const toggleRecreateMandate = () => {
    setShowRecreateMandate(!showRecreateMandate);
  };

  const toggleApprove = () => {
    setShowApprove(!showApprove);
  };
  const toggleReject = () => {
    setShowReject(!showReject);
  };

  const toggleManualDebit = () => {
    setShowManualDebit(!showManualDebit);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleEditMandateModal = () => {
    setShowEditMandate(!showEditMandate);
  };

  const toggleSendSmsModal = () => {
    setShowSendSms(!showSendSms);
  };

  const toggleRecreateEMandateModal = () => {
    setRecreateEmandate(!recreateEmandate);
  };

  // console.log("on our mandate", location?.state?.mandate);

  const navigate = useNavigate();

  const handleUpdateMandateFormChange = (e) => {
    const name = e.target?.name;
    let value;
    switch (name) {
      case "status":
        value = parseInt(e.target.value);

        break;

      case "workFlowStatus":
        value = parseInt(e.target.value);

        break;

      default:
        value = e.target.value;
        break;
    }
    // const value = e.target.value;
    setMandateForm({ ...mandateForm, [name]: value });
  };

  // const handleManualDebit = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   // const value = e.target.value;
  //   setManualDebit({ ...manualDebit, [name]: value });
  // };
  const newDebitMandate = (id, refetch) => {
    setIsManualDebit(true);
    const value= {
      // ...manualDebit,
      debitReason: debitReason,
      mandateCode: mandateDetails.mandateCode,
      subscriberCode: mandateDetails.subscriberCode,
      mandateBankCode: mandateDetails.mandateBankCode,
      accountNumber: mandateDetails.accountNumber,
      narration: mandateDetails.narration,
      amount: mandateDetails.amount,
    }
    if(mandateDetails?.activationType == 'e-mandate'){
      value.activationType = mandateDetails?.activationType
      value.eMandateCode = mandateDetails?.eMandateCode
    }
    debitMandate(
      value,
      user,
      mandateDetails.amount,
      id
    )
      .then((res) => {
        notify("success", "success", res?.message);
        setIsManualDebit(false);
        setShowManualDebit(false);
        refetch();
      })
      .catch((error) => {
        console.log(error);
        setIsManualDebit(false);
        notify("danger", "OOPS", error.response.data.response.message);
      });
  };

  const handleDeleteMandate = () => {
    setIsDeleting(true);
    deleteMandate(mandateDetails?.id, user)
      .then((res) => {
        setIsDeleting(false);
        notify("success", "success", res?.message);
        toggleDeleteModal();
        navigate(-1);
      })
      .catch((error) => {
        setIsDeleting(false);
        notify("danger", "OOPS", error?.response?.data?.message);
        console.log(error);
      });
  };
  const handleError = (err) => {
    console.error("Failed to load PDF:", err);
    // setError(err);
  };
  const updateMandate = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const constructedMandate = {
        auth: {
        },
        mandateUpdateRequests: [
          {
            mandateCode: mandateForm.mandateCode,
            phoneNumber: mandateForm.phoneNumber,
            payerName: mandateForm.payerName,
            payerAddress: mandateForm.payerAddress,
            status: mandateForm.status,
            workFlowStatus: mandateForm.workFlowStatus,
            emailAddress: mandateForm.emailAddress,
          },
        ],
      };
      // e.preventDefault();

      setIsUpdatingMandate(true);
      const updateResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/nibss/update?token=${token}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(constructedMandate),
        }
      ).then((res) => res.json());
      setIsUpdatingMandate(false);
      if (updateResponse?.code === "200") {
        notify("success", "Mandate Updated", "Mandate updated succesfully");
        setShowUpdate(false);
        refetch();
        // await createNewMandate({
        //   ...constructedMandate?.mandateRequests[0],
        //   userId: user?.userData?.id,
        //   mandateCode: updateResponse?.response[0]?.mandateCode,
        // })
        //   .then((res) => {
        //     console.log(res);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else {
        notify("danger", "OOPS", "Something went wrong");
      }
    } catch (error) {
      setIsUpdatingMandate(false);
      console.log(error);
      notify("danger", "OOPS", "something went wrong");
    }
  };
  const updateFields = () => {
    setMandateForm({
      mandateCode: mandateDetails.mandateCode,
      phoneNumber: mandateDetails.phoneNumber,
      payerName: mandateDetails.payer,
      payerAddress: mandateDetails.payerAddress,
      status: mandateDetails.status,
      workFlowStatus: mandateDetails.workFlowStatus,
      emailAddress: mandateDetails.email,
    });
    toggleUpdate();
  };

  const { data, status, refetch, isFetching, isSuccess, isLoading } = useQuery(
    "my-mandates",
    async () => {
      const getItem = JSON.parse(sessionStorage.getItem("location"));

      return (
        await retrieveMandate(
          getItem?.state?.mandate?.subscriberCode,
          getItem?.state?.mandate?.id
        )
          .then((res) => {
            if (res?.status === "Failed") {
              notify("danger", "Error", res?.response);
              // sessionStorage.removeItem("token");
              // navigate("/login");
              // window.location.reload();
              setIsFetchingMandates(false);
              return;
            } else {
              // if (!location?.state?.mandate) {
              //   setMandateDetails(res?.response[0]);
              // }
              console.log("works fine ", res?.response);
              setMandateDetails(res?.response);
              setIsFetchingMandates(false);
              // console.log(mandate);
              // setShow(true);
            }
          })
          .catch((error) => {
            console.log(error);
            notify("danger", "Error", "Something went wrong");
          }),
        {
          onError: (error) => {
            notify("danger", "Error", "Something went wrong");
            console.log(error);
          },
        }
      );
    }
  );

  const handleSuspendMandate = async (resumption, note) => {
    setIsSuspending(true);
    suspendMandate(mandateDetails.id, resumption, user, note)
      .then((res) => {
        setShowSuspendMandate(false);
        setIsSuspending(false);
        notify("success", "Success", res.message);
        setMandateDetails(res.response);
      })
      .catch((error) => {
        console.log(error);
        notify("danger", "OOPS", error.response.data.response.message);
        setIsSuspending(false);
      });
  };

  const handleUpdateResumptionDate = async (resumption, note) => {
    setIsUpdating(true);
    updateResumptionDate(mandateDetails, resumption, user, note)
      .then((res) => {
        setShowUpdateResumptionDate(false);
        setIsUpdating(false);
        notify("success", "Success", res.message);
        setMandateDetails(res.response);
      })
      .catch((error) => {
        console.log(error);
        notify("danger", "OOPS", error.response.data.response.message);
        setIsUpdating(false);
      });
  };

  const token = sessionStorage.getItem("token");

  const getBankInfo = (code) => {
    const bankDetails = banks.data.find((bank) => bank.code === code);

    return bankDetails?.name;
  };

  // isFetching && console.log("currently loading...");

  // useEffect(() => {
  //   if (location?.state?.mandate) {
  //     console.log('passed ', location?.state?.mandate);
  //     // setMandateDetails(location?.state?.mandate);
  //   }
  // }, [location]);

  const updateMandates = (type) => {
    try {
      setIsUpdating(true);
      updateMandateDetails(
        mandateDetails?.id,
        type,
        rejectionReason,
        user,
        token
      )
        .then((res) => {
          setIsUpdating(false);
          setShowApprove(false);
          setShowReject(false);
          notify(
            "success",
            "Successful",
            type === "approved" ? "Mandate Approved" : "Mandate Rejected"
          );

          setMandateDetails(res?.response);
        })
        .catch((error) => {
          console.log("this error", error);
          setIsUpdating(false);
          // if (error.response.data.response.response[0].responseCode === "99") {
          //   notify(
          //     "danger",
          //     "OOPS",
          //     error.response.data.response.response[0].responseDescription
          //   );
          // } else {
          //   notify("danger", "OOPS", "Something went wrong");
          // }
          console.log("this is error: ", error);
        });
    } catch (error) {
      console.log("this is error: ", error);
      notify("danger", "OOPS", "Something went wrong");
      setIsUpdating(false);
    }
  };

  const handleResumeSuspendedMandate = async () => {
    setIsResuming(true);

    await resumeSuspendedMandate(mandateDetails?.id, user)
      .then((res) => {
        setIsResuming(false);
        setShowResumeMandate(false);
        setMandateDetails(res?.response);
        notify("success", "success", res?.message);
      })
      .catch((error) => {
        setIsResuming(false);
        notify("danger", "OOPS", error.response.data.response.message);
      });
  };

  const handleRecreateMandate = async () => {
    setIsResuming(true);
    const sentData = {
      data: mandateDetails?.subscriberCode
    }
    // console.log('sent data recreate ', sentData)
    await recreateMandate(sentData)
      .then((res) => {
        setIsResuming(false);
        notify("success", "success", res?.message);
      })
      .catch((error) => {
        setIsResuming(false);
        notify("danger", "OOPS", error.response.data.response.message);
      });
      setIsResuming(false);
  };

  const handleSendSmsMandate = async () => {
    setIsResuming(true);
    const sentData = {
      data: {
        subscriber_code: mandateDetails?.subscriberCode
      }
    }
    // console.log('sent data recreate ', sentData)
    await sendSmsMandate(sentData)
      .then((res) => {
        setIsResuming(false);
        notify("success", "success", res?.message);
      })
      .catch((error) => {
        setIsResuming(false);
        notify("danger", "OOPS", error.response.data.response.message);
      });
        setIsResuming(false);
  };

  const frequencies = [
    { code: 1, name: "Monthly" },
    { code: 3, name: "Quarterly" },
    { code: 6, name: "Half-yearly" },
    { code: 12, name: "Yearly" },
    { code: 99, name: "Daily" },
  ];

  const mandateFrequency = frequencies?.find(
    (frequency) => frequency?.code === mandateDetails?.frequency
  );
  const approvalStatus = statuses?.data?.find(
    (status) => status?.id === mandateDetails?.approverStatus
  );

  // console.log(mandateDetails);

  const checkExtension = (file) => {
    if (file) {
      const fileExtension = file.split(".").pop();

      // console.log(fileExtension);
      return fileExtension;
    } else {
      console.log("no file");
    }
  };

  return isLoading ? (
    <>loading, please wait...</>
  ) : (
    <>
      <div className="mt-3 d-flex align-items-center flex-row justify-content-between">
        <div>
          <h6 className=" mb-0">
            Mandate Code - ({mandateDetails?.mandateCode})
          </h6>
          <p
            style={{
              marginBottom: 0,
              fontSize: "0.9rem",
              color: "#007dcc",
              fontWeight: 300,
            }}
          >
            {mandateDetails?.mandateType}
          </p>
        </div>
        <code style={{ color: "#002C47" }}>
          {mandateDetails?.approverStatus !== 4
            ? approvalStatus?.name
            : approvalStatus?.name +
              `(Resumption: ${new Date(
                mandateDetails?.resumptionDate
              ).toLocaleDateString()})`}
          {mandateDetails?.approverStatus === 4 && (
            <CiEdit
              style={{
                color: "#007dcc",
                fontSize: "1.3rem",
                cursor: "pointer",
              }}
              onClick={toggleUpdateResumptionDate}
            />
          )}
        </code>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-row mt-3 mb-3">
        <Dropdown>
          <Dropdown.Toggle
            style={{
              fontWeight: 600,
              fontSize: "0.8rem",
              backgroundColor: "transparent",
              color: "#007dcc",
              border: "none",
              padding: 0,
            }}
            id="dropdown-basic"
          >
            Options
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ fontSize: "0.7rem" }}>
            {/* {user?.userData?.role === 2 && (
                  // <Dropdown.Item
                  //   onClick={updateFields}
                  //   className="text-decoration-underline"
                  //   style={{ cursor: "pointer", color: "#007dcc" }}
                  // >
                  //   Update mandate
                  // </Dropdown.Item>
                )} */}

            {(mandateDetails?.eMandateCode || mandateDetails?.mandateCode) && (
              <Dropdown.Item onClick={toggleManualDebit}>
                Manual Debit
              </Dropdown.Item>
            )}

            <Dropdown.Item onClick={toggleEditMandateModal}>
              Edit Mandate
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={toggleSendSmsModal}>
              Send SMS
            </Dropdown.Item> */}

            {mandateDetails?.mandateType == "E-Mandate" && (mandateDetails?.workFlowStatus?.includes('2') || 
   mandateDetails.workFlowStatus?.includes('7') || 
   mandateDetails.workFlowStatus?.includes('9')) && <Dropdown.Item onClick={toggleRecreateMandate}>
              Recreate E-Mandate
            </Dropdown.Item>}

            {mandateDetails?.approverStatus == 1 && (
              <Dropdown.Item onClick={toggleSuspendMandate}>
                Suspend Mandate
              </Dropdown.Item>
            )}

            {(mandateDetails?.approverStatus === 4 ||
              mandateDetails?.approverStatus === 5) && (
              <Dropdown.Item onClick={toggleResumeMandate}>
                Resume Mandate
              </Dropdown.Item>
            )}
            <>
              <DropdownDivider />
              <Dropdown.Item
                onClick={toggleDeleteModal}
                style={{ cursor: "pointer", color: "red" }}
              >
                Delete Mandate
              </Dropdown.Item>
            </>
          </Dropdown.Menu>
        </Dropdown>
        {/* <p onClick={toggleDrawer(anchor, true)}>View mandate history</p> */}

<div className="d-flex">
        <p
          className="text-primary cursor-pointer mt-2 mb-0"
          style={{
            fontSize: "0.8rem",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={toggleDrawer(anchor, true)}
        >
          <i className="bx bx-plus align-middle"></i> View mandate history
        </p>
        <p
          className="text-primary cursor-pointer mt-2 mb-0"
          style={{
            fontSize: "0.8rem",
            textDecoration: "underline",
            cursor: "pointer",
            marginLeft: "10px"
          }}
          onClick={toggleDrawerTransact(anchorLeft, true)}
        >
          <i className="bx bx-plus align-middle"></i> View transaction history
        </p>
        </div>
      </div>
      <Row style={{ height: " calc(100vh - 150px)", overflow: "hidden" }}>
        <Col xs={6} style={{ height: "100%", overflowY: "scroll" }}>
          <Divider className="mb-3" component="div" role="presentation">
            <Chip
              style={{ color: "#007dcc" }}
              label="Personal details"
              size="small"
            />
          </Divider>

          <div className="row">
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Payer</p>
              <h6 style={{ fontSize: "0.8rem" }}>{mandateDetails?.payer}</h6>
            </div>
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Payer's Address
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.payerAddress}
              </h6>
            </div>
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Phone Number
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.phoneNumber}
              </h6>
            </div>
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Email</p>
              <h6 style={{ fontSize: "0.8rem" }}>{mandateDetails?.email}</h6>
            </div>
            <div className="col-12 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Bank</p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails && getBankInfo(mandateDetails?.bank)}
              </h6>
            </div>
          </div>

          <Divider className="mb-3 mt-3" component="div" role="presentation">
            <Chip
              style={{ color: "#007dcc" }}
              label="Mandate details"
              size="small"
            />
          </Divider>

          <div className="row">
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Subscriber Code
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.subscriberCode}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Account Name
              </p>
              <p style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.accountName}
              </p>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Account Number
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.accountNumber}
              </h6>
            </div>
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Mandate Start Date
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {new Date(mandateDetails?.startDate).toLocaleDateString()}
              </h6>
            </div>
            <div className="col-6 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Mandate Expiry Date
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {new Date(mandateDetails?.endDate).toLocaleDateString()}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Frequency</p>
              <p style={{ fontSize: "0.8rem" }}>{mandateFrequency?.name}</p>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Amount to debit
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>N{mandateDetails?.amount}</h6>
            </div>

            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Narration</p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.narration}
              </h6>
            </div>

            {/* <a
              href={mandateDetails?.mandateFile}
              target="_blank"
              rel="noopener noreferrer"
            >
              view mandate image
            </a> */}
          </div>

          <Divider className="mb-3 mt-3" component="div" role="presentation">
            <Chip
              style={{ color: "#007dcc" }}
              label="Nibss details"
              size="small"
            />
          </Divider>

          <div className="row">
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Biller</p>
              <h6 style={{ fontSize: "0.8rem" }}>{mandateDetails?.Biller}</h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Biller Account Name
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.billerAccountName}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Biller Account Number
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.billerAccountNumber}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Biller Bank
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.billerBankName}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Product/Service
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.productName}
              </h6>
            </div>

            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>BVN</p>
              <h6 style={{ fontSize: "0.8rem" }}>{mandateDetails?.bvn}</h6>
            </div>

            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>Status</p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.nibssStatus}
              </h6>
            </div>

            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>
                Work Flow Status
              </p>
              <h6 style={{ fontSize: "0.8rem" }}>
                {mandateDetails?.workFlowStatusDescription}
              </h6>
            </div>
            <div className="col-4 mb-3">
              <p style={{ color: "#525f71", fontSize: "0.8rem" }}>RC</p>
              <h6 style={{ fontSize: "0.8rem" }}>{mandateDetails?.rC}</h6>
            </div>
          </div>
          {user?.userData?.role === 1 && !mandateDetails?.mandateCode && (
            <div className="d-flex align-items-center gap-3">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: 0,
                  color: "green",
                  fontSize: "0.8rem",
                  textDecoration: "underline",
                }}
                onClick={toggleApprove}
              >
                <FiCheck /> Approve
              </button>

              <button
                style={{
                  backgroundColor: "transparent",
                  border: 0,
                  color: "red",
                  fontSize: "0.8rem",
                  textDecoration: "underline",
                }}
                onClick={toggleReject}
              >
                <GiCancel /> Reject
              </button>
            </div>
          )}
        </Col>
        <Col
          xs={6}
          style={{ height: "calc(100vh - 100px)" }}
          className="d-flex align-items-center justify-content-center"
        >
          {mandateDetails &&
          checkExtension(mandateDetails?.mandateFile) === "pdf" ? (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={`${process.env.REACT_APP_BASE_URL}/mandate/get-pdf?id=${mandateDetails?.id}`}
                onError={handleError}
                plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          ) : (
            <img
              src={mandateDetails?.mandateFile}
              alt="mandate"
              style={{ width: "85%" }}
            />
          )}
        </Col>
      </Row>

      <UpdateMandateModal
        toggleUpdate={toggleUpdate}
        showUpdate={showUpdate}
        mandateForm={mandateForm}
        setMandateForm={setMandateForm}
        updateMandate={updateMandate}
        isUpdatingMandate={isUpdatingMandate}
        handleUpdateMandateFormChange={handleUpdateMandateFormChange}
      />

      <ApproveMandateModal
        showApprove={showApprove}
        toggleApprove={toggleApprove}
        updateMandates={updateMandates}
        isUpdating={isUpdating}
      />
      <RejectMandateModal
        showReject={showReject}
        toggleReject={toggleReject}
        updateMandates={updateMandates}
        isUpdating={isUpdating}
        setRejectionReason={setRejectionReason}
        rejectionReason={rejectionReason}
      />

      <DeleteMandateModal
        show={showDeleteModal}
        toggle={toggleDeleteModal}
        handleDeleteMandate={handleDeleteMandate}
        isDeleting={isDeleting}
      />

      <SuspendMandateModal
        show={showSuspendMandate}
        toggle={toggleSuspendMandate}
        handleSuspendMandate={handleSuspendMandate}
        isSuspending={isSuspending}
      />

      <ResumeMandateModal
        show={showResumeMandate}
        toggle={toggleResumeMandate}
        handleResumeMandate={handleResumeSuspendedMandate}
        isResuming={isResuming}
      />

<RecreateMandateModal
        show={showRecreateMandate}
        toggle={toggleRecreateMandate}
        handleRecreateMandate={handleRecreateMandate}
        isResuming={isResuming}
      />

<SendSmsModal
        show={showSendSms}
        toggle={toggleSendSmsModal}
        handleRecreateMandate={handleSendSmsMandate}
        isResuming={isResuming}
      />

      <UpdateResumptionDateModal
        show={showUpdateResumptionDate}
        toggle={toggleUpdateResumptionDate}
        isUpdating={isUpdating}
        handleUpdateResumptionDate={handleUpdateResumptionDate}
      />

      {mandateDetails && (
        <ManualDebitModal
          showManualDebit={showManualDebit}
          toggleManualDebit={toggleManualDebit}
          // manualDebit={manualDebit}
          // setManualDebit={setManualDebit}
          amount={mandateDetails?.amount}
          setDebitReason={setDebitReason}
          // handleManualDebit={handleManualDebit}
          newDebitMandate={newDebitMandate}
          isManualDebit={isManualDebit}
          mandateDetails={mandateDetails}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
        />
      )}

      {mandateDetails && (
        <EditMandateModal
          show={showEditMandate}
          toggle={toggleEditMandateModal}
          mandateDetails={mandateDetails}
          mandateCode={mandateDetails.mandateCode}
          setMandateDetails={setMandateDetails}
          // handleChange={handleChange}
          // mandate={mandate}
        />
      )}

      {mandateDetails && (
        <MandateHistory
          state={state}
          anchor={anchor}
          toggleDrawer={toggleDrawer}
          subscriberCode={mandateDetails?.subscriberCode}
          statuses={statuses}
        />
      )}

{mandateDetails && (
        <TransactionHistory
          state={stateTransact}
          anchor={anchor}
          toggleDrawer={toggleDrawerTransact}
          subscriberCode={mandateDetails?.subscriberCode}
          statuses={statuses}
        />
      )}
    </>
  );
};
