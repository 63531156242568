import logo from "./logo.svg";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import { Root } from "./pages/Root";
import { ErrorPage } from "./pages/ErrorPage";
import { Dashboard } from "./pages/Dashboard";
import { CreateMandate } from "./pages/CreateMandate";
import { AllMandates } from "./pages/AllMandates";
import { Products } from "./pages/Products";
import { Reports } from "./pages/Reports";
import { MandateDetails } from "./pages/MandateDetails";
import { Login } from "./pages/Login";
import { AuthContext } from "./helpers/AuthContext";
import { useEffect, useState } from "react";
import { getFinancialInstitutions, getRoles } from "./services";
import { AdminPanel } from "./pages/AdminPanel";
import { Transactions } from "./pages/Transactions";
import useActivityMonitor from "./components/actions/useActivityMonitor";
import { notify } from "./components/actions/CommonActions";
import { ScheduleDebit } from "./pages/ScheduleDebit";
import { ScheduleList } from "./pages/ScheduleList";
import AdminAppReport from "./pages/AdminAppReport";
import Settings from "./pages/Settings";
// import ScheduleDebit from "./pages/ScheduleDebit";

function App() {
  const [user, setUser] = useState();
  const [statuses, setStatuses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [frequencies, setFrequencies] = useState([]);
  const [banks, setBanks] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [isFilterActive, setIsFilterActive] = useState(false);
  const isFilterActive = sessionStorage.getItem("isFilterActive")
  // const navigate = useNavigate();

  const refreshToken = () => {
    // Call your token refresh function here
    // console.log("Refreshing token...");
  };

  const isActive = useActivityMonitor(refreshToken);

  useEffect(() => {
    if (!isActive) {
      // Redirect or perform any action when user becomes inactive
      sessionStorage.removeItem("token");
      // navigate("/login");
      window.location.reload();
      notify(
        "warning",
        "User Inactive",
        "Session expired due to inactivity, please login"
      );
      // console.log("User is inactive.");
    }
  }, [isActive]);

  useEffect(() => {
    const userData = sessionStorage.getItem("userdata") || null;
    const parsedData = JSON.parse(userData);

    parsedData?.auth === true
      ? setIsAuthenticated(true)
      : setIsAuthenticated(false);

    if (parsedData?.auth === true) {
      setLoading(true);

      const fetchData = async () => {
        const data = sessionStorage.getItem("userdata") || null;
        let parsedData = JSON.parse(data);
        const authToken = parsedData.token;

        try {
          // Make requests to fetch statuses and roles concurrently
          const [
            statusesResponse,
            rolesResponse,
            frequenciesResponse,
            banksResponse,
            institutionsResponse,
          ] = await Promise.all([
            fetch(`${process.env.REACT_APP_BASE_URL}/statuses/get-statuses`, {
              headers: { Authorization: `Bearer ${authToken}` },
            }),
            fetch(`${process.env.REACT_APP_BASE_URL}/roles/get-roles`, {
              headers: { Authorization: `Bearer ${authToken}` },
            }),
            fetch(
              `${process.env.REACT_APP_BASE_URL}/frequency/get-frequencies`,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            ),
            fetch(`${process.env.REACT_APP_BASE_URL}/banks/get-banks`, {
              headers: { Authorization: `Bearer ${authToken}` },
            }),
            fetch(`${process.env.REACT_APP_BASE_URL}/banks/get-banks`, {
              // Fixed potential typo here (check the URL)
              headers: { Authorization: `Bearer ${authToken}` },
            }),
          ]);

          const statusesData = await statusesResponse.json();
          const rolesData = await rolesResponse.json();
          const frequenciesData = await frequenciesResponse.json();
          const banksData = await banksResponse.json();
          const institutionsData = await institutionsResponse.json();

          // Update the state with the fetched data
          setStatuses(statusesData);
          setRoles(rolesData);
          setFrequencies(frequenciesData);
          setBanks(banksData);
          setInstitutions(institutionsData);

          // Store the data in sessionStorage
          sessionStorage.setItem("statuses", JSON.stringify(statusesData));
          sessionStorage.setItem("roles", JSON.stringify(rolesData));
          sessionStorage.setItem(
            "frequencies",
            JSON.stringify(frequenciesData)
          );
          sessionStorage.setItem("banks", JSON.stringify(banksData));
          sessionStorage.setItem(
            "institutions",
            JSON.stringify(institutionsData)
          );

          // Set loading to false to indicate that data fetching is complete
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };

      // Check if statuses and roles are already in sessionStorage
      const storedStatuses = sessionStorage.getItem("statuses");
      const storedRoles = sessionStorage.getItem("roles");
      const storedFrequencies = sessionStorage.getItem("frequencies");
      const storedBanks = sessionStorage.getItem("banks");
      const storedInstitutions = sessionStorage.getItem("institutions");

      if (
        storedStatuses &&
        storedRoles &&
        storedFrequencies &&
        storedBanks &&
        storedInstitutions
      ) {
        // If data is found in sessionStorage, parse and set it in the state
        setStatuses(JSON.parse(storedStatuses));
        setRoles(JSON.parse(storedRoles));
        setFrequencies(JSON.parse(storedFrequencies));
        setBanks(JSON.parse(storedBanks));
        setInstitutions(JSON.parse(storedInstitutions));
        setLoading(false);
      } else {
        // If data is not found, fetch it
        fetchData();
      }
    } else {
      // console.log("Not Authenticated");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Check if there is a stored route
    const storedRoute = sessionStorage.getItem("currentRoute");
    if (storedRoute) {
      window.location.pathname = storedRoute; // Redirect to the stored route
    } else {
      setLoading(false); // Set loading to false when the app is ready
    }
  }, []);

  // Save the current route to session storage
  const handleRouteChange = () => {
    console.log(window.location.pathname);
    sessionStorage.setItem("currentRoute", window.location.pathname);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "create-mandate",
          element: <CreateMandate />,
        },
        {
          path: "all-mandates",
          element: <AllMandates />,
        },
        {
          path: "products",
          element: <Products />,
        },
        {
          path: "transactions",
          element: <Transactions />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "mandate-details/:id",
          element: <MandateDetails />,
        },
        {
          path: "admin-panel",
          element: <AdminPanel />,
        },
        // {
        //   path: "debit-schedule",
        //   element: <ScheduleDebit />,
        // },
        // {
        //   path: "schedule-list/:id",
        //   element: <ScheduleList />,
        // },
        // {
        //   path: "admin-report",
        //   element: <AdminAppReport />,
        // },
        // {
        //   path: "settings",
        //   element: <Settings />,
        // },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
  ]);
  return (
    <>
      {loading ? (
        <div className="typewriter-container">
          <div class="typewriter">
            <div class="slide">
              <i></i>
            </div>
            <div class="paper"></div>
            <div class="keyboard"></div>
          </div>
          <code style={{ color: "#000" }}>Getting your app ready...</code>
        </div>
      ) : (
        <>
          <AuthContext.Provider
            value={{
              user,
              setUser,
              roles,
              statuses,
              banks,
              frequencies,
              institutions,
              isAuthenticated,
              setIsAuthenticated,
              loading,
              setLoading,
              isFilterActive, 
            }}
          >
            <RouterProvider router={router} onChange={handleRouteChange} />
          </AuthContext.Provider>
        </>
      )}
    </>
  );
}

export default App;
