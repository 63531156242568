import { Box, Divider, Drawer } from "@mui/material";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  getMandateHistory,
  getTransactionHistory,
  getUsers,
} from "../../services";
import { notify } from "../actions/CommonActions";
import { AuthContext } from "../../helpers/AuthContext";
import moment from "moment";

export const TransactionHistory = ({
  anchor,
  state,
  toggleDrawer,
  subscriberCode,
}) => {
  const [history, setHistory] = useState();
  const [users, setUsers] = useState();
  const { statuses } = useContext(AuthContext);

  let sentData = {
    subscriber_code: subscriberCode,
  };

  const { data, status, isFetching, isLoading } = useQuery(
    "mandate-transaction-history",
    () =>
      getTransactionHistory(sentData)
        .then((res) => {
          if (res?.status === "failed") {
            notify("danger", "Error", res?.response);
            // setIsFetchingMandates(false);
            console.log("errrrrror transaction ", res);
            return;
          } else {
            // setHistory(res?.response);
            console.log("transaction this is the response", res);
            setHistory(res);
          }
        })
        .catch((error) => {
          console.log(error);
          notify("danger", "Error", "Something went wrong");
        }),
    {
      enabled: !!subscriberCode,
      onError: (error) => {
        notify("danger", "Error", "Something went wrong");
        console.log(error);
      },
    }
  );

  const {} = useQuery("all-users", getUsers, {
    onSuccess: (res) => {
      setUsers(res?.response);
    },
    onError: (error) => {
      notify("danger", "Error", "Something went wrong");
      console.log(error);
    },
  });

  // console.log("uuuuuuseeeeerrrrss", users);

  return (
    <div>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box
          sx={{
            width: anchor === "top" || anchor === "bottom" ? "auto" : 450,
            overflowY: "scroll",
          }}
          role="presentation"
          className="p-4"
        >
          <p style={{ color: "#344054", fontSize: "20px", fontWeight:"600" }}>
            Transaction history
            
          </p>

<div style={{backgroundColor: "#F9FAFB", padding:"20px"}}>
<p style={{ color: "#344054", fontSize: "20px", fontWeight:"600" }}>{subscriberCode}</p>
          {history?.data?.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                borderBottom: "1px solid #0000001F",
              }}
            >
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction date:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{moment(item?.createdAt).format("MMMM Do YYYY")}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction time:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{moment(item?.createdAt).format("h:mm:ss A")}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction amount:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{(Number(item?.amount) || 0)?.toLocaleString("en-NG", {
                  style: "currency",
                  currency: "NGN",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                </p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction status:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px", color: item?.status == "success" ? "green" : "red",}}>{item?.status}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction initiated by:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px"}}>{item?.initiatedBy}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction response message:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px", textAlign: 'right'}}>{item?.errorMessage}</p>
              </div>
              <div style={{display: 'flex', justifyContent: "space-between" ,marginBottom: "10px", alignItems: "center"}}>
              <p style={{ fontSize: "14px", color: "#475467", marginBottom: "0px" }}>Transaction response code:</p>
              <p style={{fontSize:"16px", fontWeight: "700", marginBottom: "0px", textAlign: 'right'}}>{item?.errorCode}</p>
              </div>
              
            </div>
          ))}
          </div>
        </Box>
      </Drawer>
    </div>
  );
};
