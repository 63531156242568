import * as React from "react";
import axios from "axios";
import { notify } from "../components/actions/CommonActions";
// import { baseurl } from "../config/config";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
//axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post["Accept"] = "application/json";
//axios.defaults.headers.common.accept = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    const data = sessionStorage.getItem("token") || null;
    if (data) {
      // let parsedData = JSON.parse(data);
      // const token = parsedData.token;
      config.headers["Authorization"] = `Bearer ${data}`;
    }
    return config;
  },
  (error) => {
    console.log("This is an error: ", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the request was unauthorized and has not already been retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark request as retried

      try {
        const accessToken = await refreshToken(); // Attempt to refresh token
        // sessionStorage.setItem("token");
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`; // Update the access token in the header

        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (refreshError) {
        console.log(refreshError);
        // If token refresh fails, handle accordingly (e.g., redirect to login)
        notify("danger", "Session expired", "Please login again.");
        sessionStorage.clear(); // Optionally clear session storage
        // window.location.href = "/login"; // Redirect to login page
        return Promise.reject(refreshError);
      }
    }

    // Return any other errors
    return Promise.reject(error);
  }
);

const axiosJson = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}static/`,
  timeout: process.env.REACT_APP_TIMEOUT,
  crossdomain: true,
  mode: "no-cors",
  credentials: "same-origin",
  preflightContinue: true,
  headers: {
    //'Content-Type': 'application/json',
    Accept: "application/json",
    //'Access-Control-Allow-Origin': true,
    "Access-Control-Allow-Origin": "*",
  },
});

const instance = axios.create({
  baseURL: "http://staging-upgrade.fbninsurance.co/api/",
  timeout: process.env.REACT_APP_TIMEOUT,
  crossdomain: true,
  //mode: 'no-cors',
  //withCredentials: true,
  //credentials: 'same-origin',
  headers: {
    Authorization: "Bearer 209|DL7gNO4fJGRqGxjRCb9aJmyuIM6ZtkTPIYibn7gB",
    "Access-Control-Allow-Origin": true,
    "Content-Type": "application/json",
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// User nibbs

async function refreshToken() {
  // Assuming you store refreshToken somewhere in storage
  const refreshToken = sessionStorage.getItem("token");
  const response = await axios.post("/user/refresh-token", { refreshToken });
  // console.log("this is token response: ", response);
  const accessToken = response.data.accessToken;

  // Store the new token
  sessionStorage.setItem("token", accessToken);

  return accessToken;
}

export async function registerNewUser(values, user) {
  const { data } = await axios.post(
    `user/register?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function loginUser(email, password) {
  const { data } = await axios.get(
    `user/login?email=${email}&password=${password}`
  );

  return data;
}

export async function validateUser(values) {
  const { data } = await axios.post(`user/validate-token`, values);

  return data;
}

export async function deleteMandate(id, user) {
  const { data } = await axios.get(
    `mandate/delete-mandate?id=${id}&userId=${user?.userData?.id}&username=${user?.userData?.email}`
  );
  return data;
}

export async function createNewMandate(values, user) {
  const { data } = await axios.post(
    `mandate/create-mandate?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    { data: values }
  );
  return data;
}

export async function changePassword(values) {
  const { data } = await axios.post(`user/change-password`, values);
  return data;
}
export async function debitMandate(values, user, amount, missedId) {
  const { data } = await axios.post(
    `mandate/debit-mandate?userId=${user?.userData?.id}&username=${user?.userData?.email}&missedId=${missedId}&amount=${amount}`,
    values
  );
  return data;
}

export async function retrieveMandate(code, id) {
  const { data } = await axios.get(`mandate/get-mandate?code=${code}&id=${id}`);
  return data;
}

export async function getUsers() {
  const { data } = await axios.get(`user/get-users`);
  return data;
}

export async function getMyMandates(userId) {
  const { data } = await axios.get(`mandate/get-mandates?userId=${userId}`);
  return data;
}

export async function getMandateHistory(subscriberCode) {
  const { data } = await axios.get(
    `mandate/history?subscriberCode=${subscriberCode}`
  );
  return data;
}

export async function getFile(id) {
  const { data } = await axios.get(`mandate/get-pdf?id=${id}`);
  return data;
}

export async function getAllMandates(page, pageSize) {
  const { data } = await axios.get(
    `mandate/get-all-mandates?page=${page}&pageSize=${pageSize}`
  );
  return data;
}

export async function getFilterAllMandates(page, pageSize, filter) {
  const { data } = await axios.get(
    `mandate/filter-mandates?page=${page}&pageSize=${pageSize}&subscriberCode=${filter.subscriberCode}&mandateCode=${filter.mandateCode}&amount=${filter.amount}&approverStatus=${filter.approverStatus}&bank=${filter.bank}&nextDebitDate=${filter.nextDebitDate}&payer=${filter.payer}&startDate=${filter.startDate}&dataLoading=${filter.dataLoading}`
  );
  return data;
}

export async function getTransactions() {
  const { data } = await axios.get(`transactions/get-transactions`);
  return data;
}

export async function getFinancialInstitutions() {
  const { data } = await axios.get(`transactions/financial-institutions`);
  return data;
}

export async function nameEnquiry(accountNumber, destinationInstitutionCode) {
  const { data } = await axios.get(
    `transactions/name-enquiry?accountNumber=${accountNumber}&destinationInstitutionCode=${destinationInstitutionCode}`
  );
  return data;
}

export async function getRoles() {
  const { data } = await axios.get(`roles/get-roles`);
  return data;
}
export async function downloadSuspendFormat() {
  const { data } = await axios.get(`file/Assets/Dependants.xlsx`);
  return data;
}
export async function getStatuses() {
  const { data } = await axios.get(`statuses/get-statuses`);
  return data;
}
export async function getBanks() {
  const { data } = await axios.get(`banks/get-banks`);
  return data;
}
export async function getFrequencies() {
  const { data } = await axios.get(`frequency/get-frequencies`);
  return data;
}
export async function updateMandateDetails(
  id,
  type,
  rejectionReason,
  approver,
  token
) {
  const { data } = await axios.put(
    `mandate/update-mandate?type=${type}&rejectionReason=${rejectionReason}&id=${id}&approver=${approver?.userData?.id}&token=${token}&username=${approver?.userData?.email}`
  );
  return data;
}

export async function editMandate(values, id, user) {
  const { data } = await axios.put(
    `mandate/edit-mandate?id=${id}&userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function generateMandateReport(user, values) {
  const { data } = await axios.post(
    `mandate/mandate-report?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function generateTransactionReport(values, user) {
  const { data } = await axios.post(
    `transaction/transaction-report?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function changeRole(roleId, userId, user) {
  const { data } = await axios.put(
    `user/change-role?roleId=${roleId}&userId=${userId}&creatorId=${user?.userData?.id}&username=${user?.userData?.email}`
  );
  return data;
}

export async function deactivateUser(userId, user) {
  const { data } = await axios.put(
    `user/deactivate-user?userId=${userId}&creatorId=${user?.userData?.id}&username=${user?.userData?.email}`
  );
  return data;
}

export async function activateUser(userId, user) {
  const { data } = await axios.put(
    `user/activate-user?userId=${userId}&creatorId=${user?.userData?.id}&username=${user?.userData?.email}`
  );
  return data;
}

export async function forgotPassword(email) {
  const { data } = await axios.get(`user/forgot-password?email=${email}`);
  return data;
}

export async function getMandatesStatus(token) {
  const { data } = await axios.get(
    `mandate/get-mandates-status?token=${token}`
  );
  return data;
}

export async function getE_MandatesStatus(token) {
  const { data } = await axios.get(
    `mandate/get-emandates-status`
  );
  return data;
}

export async function getMyMissedPayments(subscriberCode) {
  const { data } = await axios.get(
    `missed/get-my-missed-payments?subscriberCode=${subscriberCode}`
  );
  return data;
}

export async function suspendMandate(id, resumption, user, note) {
  const { data } = await axios.put(
    `mandate/suspend-mandate?id=${id}&resumption=${resumption}&userId=${user?.userData?.id}&username=${user?.userData?.email}&note=${note}`
  );
  return data;
}

export async function resumeSuspendedMandate(id, user) {
  const { data } = await axios.put(
    `mandate/resume-suspended-mandate?id=${id}&userId=${user?.userData?.id}&username=${user?.userData?.email}`
  );
  return data;
}

export async function recreateMandate(values) {
  const { data } = await axios.post(
    `mandate/recreate`, values
  );
  return data;
}

export async function sendSmsMandate(values) {
  console.log('x-api-key ', process.env.REACT_X_API_KEY);
  const { data } = await axios.post(
    `api/smms/v1/send-sms`, values, {
      headers: {
        "x-api-key": "713a7479ba989f586d28abade9b087d915f1660ee4a7cde14a80e33368afbd42"
      }
    }
  );
  return data;
}


export async function batchSuspendMandate(values, user) {
  const { data } = await axios.post(
    `mandate/batch-suspend-mandate?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function batchUploadFile(values, user) {
  const { data } = await axios.post(
    `mandate/migrate-approved-mandates?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function updateResumptionDate(values, resumption, user, note) {
  const { data } = await axios.post(
    `mandate/update-resumption-date?userId=${user?.userData?.id}&username=${user?.userData?.email}&note=${note}&resumption=${resumption}`,
    values
  );
  return data;
}

// User nibss

export async function user_types() {
  const { data } = await axios.get(`statics/user-types`);
  return data;
}

export async function user() {
  const { data } = await axios.get(`user`);
  return data;
}

export async function users_it() {
  const { data } = await axios.get(`user/it`);
  return data;
}

export async function createMandate(values, user) {
  const { data } = await axios.post(
    `create?userId=${user?.userData?.id}&username=${user?.userData?.email}`,
    values
  );
  return data;
}

export async function resetPasswordValidate(values) {
  const { data } = await axios.post(`user/validate-reset-token`, values);
  return data;
}

export async function createPassword(values) {
  const { data } = await axios.post(`user/create-password`, values);
  return data;
}

export async function resetPassword(values) {
  const { data } = await axios.post(`user/reset-password`, values);
  return data;
}

export async function get_settings() {
  const { data } = await axios.get(`settings`);
  return data;
}

export async function updateSettings(values) {
  const { data } = await axios.put(`settings`, values);
  return data;
}

/* USER */
export async function userUpdate(values) {
  const { data } = await axios.post(`profile/update`, values);
  return data;
}

export const tickets = async (query) => {
  const { data } = await axios.get(`tickets/${query}`);
  return data;
};
export const ticket_statuses = async () => {
  const { data } = await axios.get(`ticket-status`);
  return data;
};
export const channels = async () => {
  const { data } = await axios.get(`channels`);
  return data;
};
export const units = async () => {
  const { data } = await axios.get(`units`);
  return data;
};
export const create_ticket = async (values) => {
  const { data } = await axios.post(`tickets`, values);
  return data;
};
export const assign_ticket = async () => {
  const { data } = await axios.post(`tickets/user-assign-tickets`);
  return data;
};
export const ticket_categories = async () => {
  const { data } = await axios.get(`ticket-categories`);
  return data;
};
export const ticket_sub_categories = async () => {
  const { data } = await axios.get(`ticket-sub-categories`);
  return data;
};
export const ticket_types = async () => {
  const { data } = await axios.get(`ticket-types`);
  return data;
};
// export const ticket_details = async (query) => {
//   const { data } = await axios.get(`tickets/details/${query}`);
//   return data;
// };
export const update_ticket_status = async ({ id, values }) => {
  const { data } = await axios.put(`tickets/update-status/${id}`, values);
  return data;
};

export const closeTicket = async (id, comment) => {
  const { data } = await axios.put(`tickets/update-status/${id}`, {
    status_id: 6,
    comment: comment,
  });
  return data;
};

export const forwardTicket = async (id, unit) => {
  const { data } = await axios.post(`tickets/forward-ticket`, {
    ticket_id: id,
    unit_key: unit,
  });
  return data;
};

export const updateTicket = async (id, tid, comment) => {
  const { data } = await axios.put(`tickets/update-status/${tid}`, {
    status_id: id,
    comment: comment,
  });
  return data;
};

export const addAttachment = async (tid, values) => {
  const { data } = await axios.put(
    `tickets/update-status/upload-attachment/${tid}`,
    values
  );
  return data;
};

export const ticket_policy = async (values) => {
  const { data } = await axios.post(`tickets/policy`, values);
  return data;
};

export const ticket_unassigned = async () => {
  const { data } = await axios.get(`tickets/unassigned-count`);
  return data;
};

export const get_assignments = async () => {
  const { data } = await axios.get(`assignments`);
  return data;
};

export const benefitRequest = async (values) => {
  const { data } = await axios.post(`tickets`, values);
  return data;
};

export const updateStatus = async (values) => {
  const { data } = await axios.post(`tickets/update-status`, values);
  return data;
};

export const assignTickets = async (values) => {
  const { data } = await axios.post(`tickets/assign`, values);
  return data;
};

export const reassignTickets = async (values) => {
  const { data } = await axios.post(`tickets/reassign`, values);
  return data;
};

export const deleteTicket = async ({ id, values }) => {
  //console.log({ id, values }, '{ id, values }____')
  const { data } = await axios.put(`tickets/truncate/${id}`, values);
  return data;
};

export const ticket_details = async (id) => {
  const { data } = await axios.get(`tickets/details/${id}`);
  return data;
};

/* Users management */
// export const users = async (query) => {
//   const { data } = await axios.get(`user/users${query}`);
//   return data;
// };

export const users = async () => {
  const { data } = await axios.get(`users`);
  return data;
};

export const userAdd = async (values) => {
  const { data } = await axios.post(`users`, values);
  return data;
};

export const userUpload = async (values) => {
  const { data } = await axios.post(`user/upload`, values);
  return data;
};

export const userEdit = async ({ id, values }) => {
  const { data } = await axios.put(`users/${id}`, values);
  return data;
};

export const userSetPassword = async ({ id, values }) => {
  //console.log(id, values, 'values...')
  const { data } = await axios.put(`user/set-password/${id}`, values);
  return data;
};

export const userDelete = async (id) => {
  const { data } = await axios.delete(`user/${id}`);
  return data;
};

export const user_actions = async (values) => {
  const { data } = await axios.post(`user/actions`, values);
  return data;
};

export const claims = async (policy_number) => {
  const { data } = await axios.get(`policies/claims/${policy_number}`);
  return data;
};

export const claim_details = async (claim_number) => {
  const { data } = await axios.get(`policies/claim/${claim_number}`);
  return data;
};

export const policy_details = async (policy_number) => {
  //await sleep(1000);
  //const { data } = await Promise.resolve(policy__details);
  const { data } = await axios.get(`policies/policy/${policy_number}`);
  return data;
};

/* export const uploadFile = async (values) => {
  const { data } = await axios.post(`tickets/upload-file`, values);
  return data;
} */

export const uploadFile = async (values) => {
  const { data } = await axios.post(
    `http://staging-upgrade.fbninsurance.co/api/file-upload`,
    values
  );
  return data;
};

/* Reporting */
export const summary = async () => {
  const { data } = await axios.get(`reports/summary`);
  return data;
};

export const ticket_type_summary = async () => {
  const { data } = await axios.get(`reports/ticket-type-summary`);
  return data;
};

export const ticket_status_summary = async () => {
  const { data } = await axios.get(`reports/ticket-status-summary`);
  return data;
};

export const ticket_status_by_dates = async () => {
  const { data } = await axios.get(`reports/ticket-status-by-dates`);
  return data;
};

export const recent_tickets = async () => {
  const { data } = await axios.get(`reports/recent-tickets`);
  return data;
};

/* Schedules */
export const schedules = async (query) => {
  const { data } = await axios.get(`schedules${query}`);
  return data;
};

export const schedule_list = async (session, query) => {
  const { data } = await axios.get(`schedules/${session}${query}`);
  return data;
};

export const scheduleAdd = async (values) => {
  const { data } = await axios.post(`schedules`, values);
  return data;
};

export const scheduleDelete = async (id) => {
  const { data } = await axios.delete(`schedules/${id}`);
  return data;
};

export const scheduleRerun = async (values) => {
  const { data } = await axios.post(`schedules/rerun`, values);
  return data;
};

/* Ticket Categories */
export const ticketCategories = async () => {
  const { data } = await axios.get(`ticket-categories`);
  return data;
};

export const ticketCategoryAdd = async (values) => {
  const { data } = await axios.post(`ticket-categories`, values);
  return data;
};

export const ticketCategoryEdit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-categories/${id}`, values);
  return data;
};

export const ticketCategoryDelete = async (id) => {
  const { data } = await axios.delete(`ticket-categories/${id}`);
  return data;
};

/* Ticket Types */
export const ticketTypes = async () => {
  const { data } = await axios.get(`ticket-types`);
  return data;
};

export const ticketTypeAdd = async (values) => {
  const { data } = await axios.post(`ticket-types`, values);
  return data;
};

export const ticketTypeEdit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-types/${id}`, values);
  return data;
};

export const ticketTypeDelete = async (id) => {
  const { data } = await axios.delete(`ticket-types/${id}`);
  return data;
};

/* Departments */
export const departments = async () => {
  const { data } = await axios.get(`departments`);
  return data;
};

export const departmentAdd = async (values) => {
  const { data } = await axios.post(`departments`, values);
  return data;
};

export const departmentEdit = async ({ id, values }) => {
  console.log({ id, values }, "{ id, values }_____");
  const { data } = await axios.put(`departments/${id}`, values);
  return data;
};

export const departmentDelete = async (id) => {
  const { data } = await axios.delete(`departments/${id}`);
  return data;
};

/* Branches */
export const branches = async () => {
  const { data } = await axios.get(`branches`);
  return data;
};

export const branchAdd = async (values) => {
  const { data } = await axios.post(`branches`, values);
  return data;
};

export const branchEdit = async ({ id, values }) => {
  const { data } = await axios.put(`branches/${id}`, values);
  return data;
};

export const branchDelete = async (id) => {
  const { data } = await axios.delete(`branches/${id}`);
  return data;
};

export const bootstraps = async () => {
  let [
    user_data,
    companies_data,
    branches_data,
    roles_data,
    ticket_types_data,
    ticket_statuses_data,
    ticket_categories_data,
    ticket_sub_categories_data,
    channels_data,
    departments_data,
    units_data,
  ] = await axios.all([
    user(),
    companies(),
    branches(),
    roles(),
    ticket_types(),
    ticket_statuses(),
    ticket_categories(),
    ticket_sub_categories(),
    channels(),
    departments(),
    units(),
  ]);
  return {
    user: user_data,
    companies: companies_data,
    branches: branches_data,
    roles: roles_data,
    ticketTypes: ticket_types_data,
    ticketStatuses: ticket_statuses_data,
    ticketCategories: ticket_categories_data,
    ticketSubCategories: ticket_sub_categories_data,
    channels: channels_data,
    departments: departments_data,
    units: units_data,
  };
};

/* Notifications */
export const get_notifications = async (query) => {
  const { data } = await axios.get(`notifications${query}`);
  return data;
};

export const notifications_delete = async (id) => {
  const { data } = await axios.delete(`notifications/${id}`);
  return data;
};

/* Logs */
export const get_logs = async (query) => {
  const { data } = await axios.get(`logs${query}`);
  return data;
};

/* Reports */
export const report_current_status = async (query) => {
  const { data } = await axios.get(`reports/current-status${query}`);
  return data;
};

export const report_current_status_history = async (query) => {
  const { data } = await axios.get(`reports/current-status-history${query}`);
  return data;
};

export const assignee_summary = async (query) => {
  const { data } = await axios.get(`reports/assignee-summary${query}`);
  return data;
};

export const report_status_history = async (query) => {
  const { data } = await axios.get(`reports/status-history${query}`);
  return data;
};

/* Ticket Status options */
export const status_options = async () => {
  const { data } = await axios.get(`ticket-status-options`);
  return data;
};

export const status_options_add = async (values) => {
  const { data } = await axios.post(`ticket-status-options`, values);
  return data;
};

export const status_options_edit = async ({ id, values }) => {
  const { data } = await axios.put(`ticket-status-options/${id}`, values);
  return data;
};

export const status_options_delete = async (id) => {
  const { data } = await axios.delete(`ticket-status-options/${id}`);
  return data;
};

export const adAuth = async () => {
  const { data } = await axios.get(
    `user/auth/365/request?redirect_uri=https://crm360.fbninsurance.co/login`
  );
  return data;
};

export const adValidate = async (code) => {
  const { data } = await axios.post(`user/auth/365/validate`, code);
  return data;
};

// ROLES
export const roles = async () => {
  const { data } = await axios.get(`roles`);
  return data;
};

// COMPANIES
export const companies = async () => {
  const { data } = await axios.get(`companies`);
  return data;
};

//SEARCH
export const searchPolicyByPolicyNum = async (param) => {
  const { data } = await axios.get(`policies/policy/${param.data}`);
  return data;
};

export const searchPolicyByPhoneNum = async (param) => {
  const { data } = await axios.get(`policies/policy/phone/${param.data}`);
  return data;
};

//NOTE

export const addNote = async (values) => {
  const { data } = await axios.put(
    `tickets/update-status/note/${values.ticket_id}`,
    values
  );
  return data;
};

export const getNotes = async (id) => {
  const { data } = await axios.get(`tickets/notes/${id}`);
  return data;
};

// mail

export async function replyMail(id, values) {
  const { data } = await axios.put(`tickets/update-status/reply/${id}`, values);
  return data;
}

export async function sendMail(id, values) {
  const { data } = await axios.put(
    `tickets/update-status/send-email/${id}`,
    values
  );
  return data;
}

export const uploadBulkMandateDocument = async (values) => {
  console.log(values);
  const { data } = await axios.post(`mandate/migrate-approved-mandates`, values);
  return data;
};

export const updateMandateFile = async (values) => {
  console.log(values);
  const { data } = await axios.post(`mandate/update-mandate-file`, values);
  return data;
};

export const faAppReport = async () => {
  // console.log(values);
  const { data } = await axios.get(`auto/fa-app-report`);
  return data;
};

export const debittingCountApi = async (id) => {
  // console.log(values);
  const { data } = await axios.get(`/auto/debit-count/${id}`);
  return data;
};


export const getAllDebitSessions = async () => {
  // console.log(values);
  const { data } = await axios.get(`/debits/get-debit-sessions`);
  return data;
};


export const getDebitId = async (date) => {
  // console.log(values);
  const { data } = await axios.get(`/auto/get-debit-ids/${date}`);
  return data;
};


export const createDebitSession = async (values) => {
  const { data } = await axios.post(`/debits/query-create-debit-mandate`, values);
  return data;
};

export const createDebitSchedule = async (values) => {
  const { data } = await axios.post(`/debits/create-debit-mandate`, values);
  return data;
};


export const getSettings = async () => {
  // console.log(values);
  const { data } = await axios.get(`/debits/get-all-settings`);
  return data;
};

export const updateSettingsApi = async (name, value) => {
  // console.log(values);
  const { data } = await axios.get(`/debits/update-settings/${name}/${value}`);
  return data;
};

export const getTransactionHistory = async (values) => {
  const { data } = await axios.post(`mandate/trx-history`, values);
  return data;
};

export async function getScheduleDebitDetails(page) {
  const { data } = await axios.get(
    `debits/get-debits?session_id=${page}&debit_status=&process_status=`
  );
  return data;
}